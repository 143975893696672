import {h} from 'preact'
import {capitalizeFirstLetter, timeSince} from "../../utils/functions";

export default ({review}) => {
    const positiveRating = []
    const negativeRating = []
    // const dateArr = review.created_at.split(/[- :]/)
    let avatar;
    if (!review?.profile_photo_thumbnail || review.profile_photo_thumbnail.indexOf('default') > -1) {
        avatar = <div className="avatar">
            <div className="avatar-placeholder">{capitalizeFirstLetter(review.fullname).slice(0,1)}</div>
        </div>;
    } else {
        avatar = <div className="avatar">
            <img style={{width: '56px',height: '56px', objectFit: 'cover'}} src={review?.profile_photo_thumbnail}
                 alt={review?.profile_photo_thumbnail || review.fullname}/>
        </div>
    }
    //fix for iOS v14
    // const reviewDate = new Date(dateArr[0], dateArr[1]-1, dateArr[2], dateArr[3], dateArr[4], dateArr[5])
    for (let i = 0; i < review.rate; i++) {
        positiveRating.push(<img src={"/assets/images/icon-star-rating.svg"} alt=""/>)
    }
    for (let i = 0; i < 5 - review.rate; i++) {
        negativeRating.push(<img src={"/assets/images/icon-star-rating-empty.svg"} alt=""/>)
    }
    return (
        <div className="review-card" >
            {avatar}
            <div className="text">
                <div className="title-row">
                    <h4>{review.fullname}</h4>
                    <div className="date">{timeSince(new Date(review.created_at))} ago</div>
                </div>
                <div className="rating-row">
                    {positiveRating}
                    {negativeRating}
                </div>
                <p>{review.comment}</p>
            </div>
        </div>
    )
}