import {useRef, useEffect} from "preact/hooks";

export function useDidUpdate (callback, deps) {
    const hasMount = useRef(false)

    useEffect(() => {
        if (hasMount.current) {
            callback()
        } else {
            hasMount.current = true
        }
    }, deps)
}