import {h} from 'preact';

export default () => {
    return (
        <section className="text-section">
            <div className="content">
                <div className="text-content">
                    <h1>i3DESIGN Co., LTD.<br />
                        Terms Of Service For Professionals
                    </h1>
                    <p className="date">Updated: July, 2020<br />
                        i3DESIGN Co., LTD.
                    </p>
                    <div className="box">
                        <h2 id="section-1">Terms of Service for Professionals</h2>
                        <p>These Terms of Service (the "Terms of Service") govern the use of the mobile and
                            internet-based services, business tools offered by Mirron, Inc. (the "Company," "Mirron,"
                            "we" or "us") at and through the website Mirron.me (the "Website") and through mobile
                            applications (such services, applications and the Website are collectively referred to as
                            the "Services"). The Company's Privacy Policy located at <a href={'/privacy_policy'}>https://mirron.me/privacy_policy</a>,
                            as well as any subscription agreements relating to the Services (each, a "Subscription")
                            executed by you ("you" or "Professional") or accepted by you via email or online, and any
                            community guidelines or dispute resolution procedures that the Company may provide, are all
                            considered part of these Terms of Service and are incorporated by reference into these Terms
                            of Service in their entirety. In the event of a conflict between these Terms of Service and
                            any Subscription agreement, these Terms of Service will control. Any reference to these
                            Terms of Service shall include and mean a reference to all such incorporated policies,
                            guidelines, programs, schedules and processes. In the event of a conflict between these
                            Terms of Service and the any other terms posted on the Website, the terms of these Terms of
                            Service shall govern, and in the event of a conflict between these Terms of Service and the
                            End User License Agreement in mobile applications (the "EULA"), the terms of the EULA shall
                            govern. These Terms of Service apply to any use of the Services, whether in connection with
                            a Subscription or free trial.</p>
                        <p>PLEASE READ THESE TERMS OF SERVICE CAREFULLY. THIS IS A LEGAL AGREEMENT BETWEEN Mirron AND
                            YOU WHICH GOVERNS YOUR USE OF THE SERVICES. BY EXECUTING A SUBSCRIPTION AGREEMENT, AND/OR
                            YOUR USE OF THE SERVICES CONSTITUTES YOUR ACCEPTANCE OF AND AGREEMENT TO ALL OF THE TERMS
                            AND CONDITIONS IN THESE TERMS OF SERVICE AND YOUR REPRESENTATION THAT YOU ARE 18 YEARS OF
                            AGE OR OLDER. IF YOU OBJECT TO ANYTHING IN THESE TERMS OF SERVICE YOU ARE NOT PERMITTED TO
                            USE THE SERVICES.</p>
                        <p>If you are accepting these Terms of Service and using the Services on behalf of a company,
                            organization, government, or other legal entity, you represent and warrant to the Company
                            that you have full power and authority to do so.</p>
                        <p>The Services are protected by copyright laws and international copyright treaties, as well as
                            other intellectual property laws and treaties. Unauthorized reproduction or distribution of
                            the Services, or any portion of them, may result in severe civil and criminal penalties, and
                            will be prosecuted to the maximum extent possible under the law. The Services are licensed,
                            not sold.</p>
                    </div>
                    <div className="box">
                        <h2 id="section-2">The Mirron Services</h2>
                        <p>Mirron is a platform that connects salons, merchants, stylists and Professionals providing
                            hair, beauty and other health and wellness services ("Styling Services") with clients
                            seeking such services ("Clients"). The Professionals and Clients are both users of the
                            Services provided by Mirron and are hereinafter referred to collectively as "Users."</p>
                    </div>
                    <div className="box">
                        <h2 id="section-3">Mirron Platform</h2>
                        <p>Mirron solely provides a platform for Professionals and Clients to connect and serves only as
                            a medium to facilitate the provision of Styling Services. Mirron does not provide or
                            contract for Styling Services, and Professionals and Clients contract independently for the
                            provision of Styling Services. Each Client is solely responsible for selecting the
                            Professional, the Styling Services to be provided and the location at which Styling Services
                            will be performed, whether on the premises of a Professional or at a site designated by the
                            Client. Any decision by a Client to receive Styling Services or by a Professional to provide
                            Styling Services is a decision made in such person's sole discretion and at their own risk.
                            All Users understand and acknowledge that (i) the Company does not conduct background checks
                            on Clients and (ii) any provision of Services in a private location inherently increases the
                            risks involved for both Clients, Professionals and any personnel of Professionals, as
                            applicable. Each Professional must decide whether a Client is suited to such Professional's
                            services and should exercise caution and common sense to protect the personal safety and
                            property of such Professional and its personnel, as applicable, just as they would when
                            interacting with anyone you don't know. NEITHER COMPANY NOR ITS AFFILIATES OR LICENSORS IS
                            RESPONSIBLE FOR THE CONDUCT, WHETHER ONLINE OR OFFLINE, OF ANY USER OF THE SERVICES AND YOU
                            HEREBY RELEASE THE COMPANY AND ITS AFFILIATES OR LICENSORS FROM ANY LIABILITY RELATED
                            THERETO. COMPANY AND ITS AFFILIATES AND LICENSORS WILL NOT BE LIABLE FOR ANY CLAIM, INJURY
                            OR DAMAGE ARISING IN CONNECTION WITH YOUR USE OF THE SERVICES.</p>
                        <p>Mirron does not have control over the quality, suitability, reliability, timing, durability,
                            legality, failure to provide, or any other aspect whatsoever of any Styling Services
                            provided by Professionals nor of the integrity, responsibility or any of the actions or
                            omissions whatsoever of any Professionals or Clients. Mirron makes no representations or
                            warranties whatsoever with respect to Styling Services offered or provided by Professionals
                            or requested by Clients through use of the Services, whether in public, private, or offline
                            interactions, or about the accreditation, registration or licensing of any Professional. You
                            understand that Mirron does not routinely screen its Users, inquire into the background of
                            its Users or attempt to verify information provided by any User. Mirron does not verify or
                            confirm that any User is who they claim to be or is accurately representing themselves and
                            does not verify or confirm any representations with respect to Styling Services on the
                            Services. Mirron does not assume any responsibility for the accuracy or reliability of this
                            information or any information provided through the Services. Notwithstanding the foregoing,
                            Mirron may, but is not obligated to, check the background and experience of Professionals
                            via third-party background check services and first party interviews, including but not
                            limited to a verification of identity and a comprehensive criminal background check and may
                            terminate a Professional based on any information discovered during such background checks
                            and first party interviews.</p>
                        <p>In connection with certain services, including Styling Services, for which reservations are
                            made available on the Services, if approved by Mirron, you may require Clients to enter
                            credit card or other payment information, and at your discretion, you may charge fees for
                            reservations, including cancelled and missed appointments, only in accordance with any
                            policy posted on your Mirron page at the time of the Client's booking. While Mirron takes
                            what it believes to be reasonable efforts to ensure secure transmission of your information
                            to third parties who assess and process payments, Mirron is not responsible for any fees or
                            charges assessed by third parties or any errors in the processing of payments by third
                            parties, including any errors that result from third-party negligence, improper transmission
                            of payment information, a Client's mistaken submission of payment information or submission
                            of erroneous payment information. If you manually insert a Client’s credit card information
                            and save it to such Client’s account, you represent that you have permission from each
                            Client to save such Client’s credit card information, you take full responsibility for
                            adhering to each Client’s preferences with respect to such credit card information and
                            hereby agree to indemnify us for any claims arising out of failure to obtain such
                            permission.</p>
                        <p>You agree and represent that you will honor the reservations made by Clients through the
                            Services, or will communicate with them in advance to resolve any scheduling conflicts to
                            the Client's and Mirron's satisfaction.</p>
                    </div>
                    <div className="box">
                        <h2 id="section-4">Accounts</h2>
                        <h2 id="section-5">Account Registration</h2>
                        <p>By creating an account with Mirron (an "Account"), registering for a Subscription and paying
                            the applicable Subscription Fee, you are granted a right to use the Services provided by
                            Mirron subject to the restrictions set forth in these Terms of Service and any other
                            restrictions stipulated to you by us in writing. Our registration process will ask you for
                            information including your name and other personal and business information (including
                            information regarding any licensing and registration applicable to you where you provide the
                            Services). You must complete this and other processes to access any funds that you accept
                            through the Services. In registering for an Account, you agree to provide true, accurate,
                            current and complete information about yourself as prompted by the Services' registration
                            process and as requested from time to time by Mirron (such information, "Registration
                            Data"). You further agree that, in providing such Registration Data, you will not knowingly
                            omit or misrepresent any material facts or information and that you will promptly enter
                            corrected or updated Registration Data via the Services, or otherwise advise us promptly in
                            writing of any changes or updates to your Registration Data. You further consent and
                            authorize us to verify your Registration Data as required for your use of and access to the
                            Services. We reserve the right to suspend or terminate the Account of any User who provides
                            inaccurate, untrue, or incomplete information, or who fails to comply with the account
                            registration requirements.</p>
                    </div>
                    <div className="box">
                        <h2 id="section-6">Account ID</h2>
                        <p>Once you register for the Services, you shall receive a unique user identification and
                            password in connection with your Account (collectively referred to herein as "Account IDs").
                            You must use your or your business' true and accurate name when signing up for an Account.
                            You are required to select an appropriate and non-offensive Account ID. Ideally, the Account
                            ID you choose will be a reasonably descriptive name that clearly identifies you or your
                            business. This name will appear on your credit or debit card statement for all payments you
                            accept using the Services. We reserve the right to remove or reclaim any Account ID if we
                            believe, in our sole discretion, the Account ID is inappropriate, offensive or confusing or
                            potentially violates the rights of a third party (such as when a trademark owner provides
                            notice that an Account ID is similar to the trademark and the Account ID does not closely
                            relate to the User's actual name or trademark rights).</p>
                    </div>
                    <div className="box">
                        <h2 id="section-7">Your Account</h2>
                        <p>By creating an Account, you confirm that you are either a legal resident of the United
                            States, a United States citizen, or a business entity authorized to conduct business by the
                            state(s) in which you operate. The Services and your Account may only be used for business
                            purposes in the fifty states of the United States of America and the District of Columbia.
                            You may not export the Services directly or indirectly, and you acknowledge that the
                            Services may be subject to export restrictions imposed by US law, including US Export
                            Administration Regulations (15 C.F.R. Chapter VII). The above paragraph is applicable to
                            U.S.-based Users only, and international users are required to comply with applicable
                            international law.</p>
                        <p>You agree to maintain your Account solely for your own use. You agree that you will not allow
                            another person to use your Account IDs to access or use the Services under any
                            circumstances. You are solely and entirely responsible for maintaining the confidentiality
                            of your Account IDs and for any charges, damages, liabilities or losses incurred or suffered
                            as a result of your failure to do so. We are not liable for any harm caused by or related to
                            the theft of your Account IDs, your disclosure of your Account IDs, or your authorization to
                            allow another person to access or use the Services using your Account IDs. Furthermore, you
                            are solely and entirely responsible for any and all activities that occur under your Account
                            including any charges incurred relating to the Services. You agree to immediately notify us
                            of any unauthorized use of your Account or any other breach of security known to you. You
                            acknowledge that the complete privacy of your data and messages transmitted while using the
                            Services cannot be guaranteed.</p>
                    </div>
                    <div className="box">
                        <h2 id="section-8">Linking Your Account</h2>
                        <p>As part of the functionality of the Services, you may link your Account with online accounts
                            you may have with third party service providers (each such account, a "Third Party Account")
                            by either: (i) providing your Third Party Account login information through the Services; or
                            (ii) allowing Mirron to access your Third Party Account, as is permitted under the
                            applicable terms and conditions that govern your use of each Third Party Account. You
                            represent that you are entitled to disclose your Third Party Account login information to
                            Mirron and/or grant Mirron access to your Third Party Account (including, but not limited
                            to, for use for the purposes described herein), without breach by you of any of the terms
                            and conditions that govern your use of the applicable Third Party Account and without
                            obligating Mirron to pay any fees or making Mirron subject to any usage limitations imposed
                            by such third party service providers. By granting Mirron access to any Third Party
                            Accounts, you understand that (i) Mirron may access, make available and store (if
                            applicable) any content that you have provided to and stored in your Third Party Account
                            (the "SNS Content") so that it is available on and through the Services via your Account,
                            including without limitation any friend lists, and (ii) Mirron may submit and receive
                            additional information to your Third Party Account to the extent you are notified when you
                            link your Account with the Third Party Account. Unless otherwise specified in these Terms of
                            Service, all SNS Content, if any, shall be considered to be Content (as defined below) for
                            all purposes of these Terms of Service. Depending on the Third Party Accounts you choose and
                            subject to the privacy settings that you have set in such Third Party Accounts, personally
                            identifiable information that you post to your Third Party Accounts may be available on and
                            through your Account on the Services. Please note that if a Third Party Account or
                            associated service becomes unavailable or Mirron's access to such Third Party Account is
                            terminated by the third party service provider, then SNS Content may no longer be available
                            on and through the Services. You will have the ability to disable the connection between
                            your Account and your Third Party Accounts at any time. PLEASE NOTE THAT YOUR RELATIONSHIP
                            WITH THE THIRD PARTY SERVICE PROVIDERS ASSOCIATED WITH YOUR THIRD PARTY ACCOUNTS IS GOVERNED
                            SOLELY BY YOUR AGREEMENT(S) WITH SUCH THIRD PARTY SERVICE PROVIDERS. Mirron makes no effort
                            to review any SNS Content for any purpose, including but not limited to, for accuracy,
                            legality or non-infringement, and Mirron is not responsible for any SNS Content.</p>
                        <p>You acknowledge and agree that the Services may access your e-mail address book associated
                            with a Third Party Account and your contacts list stored on your mobile device or tablet
                            computer solely for the purposes of identifying and informing you of those contacts who have
                            also registered to use the Services. At your request made via e-mail to support@mirron.me,
                            Mirron will deactivate the connection between the Mirron Services and your Third Party
                            Account any information stored on Mirron's servers that was obtained through such Third
                            Party Account will be hidden, except the username and profile picture associated with your
                            Account.</p>
                        <p>The Services may contain links to third party websites that are not owned or controlled by
                            Mirron. Mirron has no control over, and assumes no responsibility for, the content, privacy
                            policies, or practices of any third party websites. In addition, Mirron will not and cannot
                            censor or edit the content of any third-party site. By using the Services, you expressly
                            relieve Mirron from any and all liability arising from your use of any third-party
                            website.</p>
                    </div>
                    <div className="box">
                        <h2 id="section-9">Termination of Subscription and Account Right to Terminate</h2>
                        <p>Mirron reserves the right, in its sole discretion, to terminate your Subscription, Account,
                            or use of the Services, if you violate these Terms of Service, or for any reason or no
                            reason at any time. We may also suspend your access to the Services and your Account
                            (including the funds in your Account) if you (a) have violated the terms of these Terms of
                            Service, any other agreement you have with Mirron, including without limitation the EULA, or
                            Mirron's policies, (b) pose an unacceptable credit or fraud risk to us, (c) provide any
                            false, incomplete, inaccurate, or misleading information or otherwise engage in fraudulent
                            or illegal conduct or (iv) for any other reason in Mirron's sole discretion. This includes
                            the right to modify or terminate any Subscription prior to the end of any pre-paid
                            period.</p>
                        <p>You may terminate these Terms of Service and/or the EULA by terminating your Account at any
                            time. Upon closure of an Account, any pending transactions will be cancelled. Any funds that
                            we are holding in custody for you at the time of closure, less any applicable fees, will be
                            paid out to you according to your payout schedule, assuming all payout-related
                            authentication requirements have been fulfilled (for example, you may not close your Account
                            as a means of evading your payout schedule). If an investigation is pending at the time you
                            close your Account, we may hold your funds as described herein. If you are later determined
                            to be entitled to some or all of the funds in dispute, we will release those funds to
                            you.</p>
                    </div>
                    <div className="box">
                        <h2 id="section-10">Termination Effects</h2>
                        <p>If your Subscription and/or Account is terminated or suspended for any reason or no reason,
                            you agree: (a) to continue to be bound by these Terms of Service, (b) to immediately stop
                            using the Services, (c) that any licenses grant to you under these Terms of Service shall
                            end, (d) that we reserve the right (but have no obligation) to hide or delete all of your
                            information and account data stored on our servers, and (e) that Mirron shall not be liable
                            to you or any third party for termination or suspension of access to the Services or for
                            deletion or hiding of your information or account data. In the event that your Subscription
                            and/or Account is terminated or suspended, Mirron reserves the right to promote you to
                            Clients using information collected during your subscription term, including but not limited
                            to, a general description of your business, business address, customer reviews or
                            photographs, but excluding your contact information (such as phone number or email address).
                            Furthermore, you agree that Mirron may retain and use your information and account data as
                            needed to comply with investigations and applicable law.</p>
                        <p>We will not be liable to you for compensation, reimbursement, or damages in connection with
                            your use of the Services, or in connection with any termination or suspension of the
                            Services. Any termination of these Terms of Service does not relieve you of any obligations
                            to pay any fees or costs accrued prior to the termination and any other amounts owed by you
                            to us as provided in these Terms of Service.</p>
                    </div>
                    <div className="box">
                        <h2 id="section-11">Professional Accounts</h2>
                        <ul>
                            <li>Professionals may use the Services to offer Styling Services only through their business
                                or personal website or software application (a "Professional Site"), and we reserve
                                approval authority as to the implementation and use of the Services on the Professional
                                Site. We may suspend the Services in the event we find any implementation issues with
                                the Professional Site. Such suspension shall remain in effect until the Professional
                                corrects any issues specified by Mirron and a suspension shall not relieve a
                                Professional of its payment obligations under these Terms of Service.
                            </li>
                            <li>By creating an Account as a Professional, you certify that you are, or if you are a
                                salon, merchant or other business entity, all of your employees, independent contractors
                                or agents who are providing the Styling Services each are, a licensed professional and
                                that your business information is correctly represented on Mirron. You further certify
                                that you are duly licensed to provide Styling Services "in-home" or otherwise outside
                                your place of business should you advertise such Styling Services on Mirron. Mirron
                                reserves the right to remove or hide any incorrect, out of date, or illegal information
                                from profiles, as well as remove or hide the entire profile itself.
                            </li>
                        </ul>
                        <p>By creating an Account as a Professional, you confirm that you will not accept payments from
                            or in connection with the following businesses or business activities: (1) any illegal
                            activity or goods, (2) buyers or membership clubs, including dues associated with such
                            clubs, (3) credit counseling or credit repair agencies, (4) credit protection or identity
                            theft protection services, (5) direct marketing or subscription offers or services, (6)
                            infomercial sales, (7) internet/mail order/telephone order pharmacies or pharmacy referral
                            services (where fulfillment of medication is performed with an internet or telephone
                            consultation, absent a physical visit with a physician including re-importation of
                            pharmaceuticals from foreign countries), (8) unauthorized multi-level marketing businesses,
                            (9) inbound or outbound telemarketers, (10) prepaid phone cards or phone services, (11)
                            rebate based businesses, (12) up-sell merchants, (13) bill payment services, (14) betting,
                            including lottery tickets, casino gaming chips, off-track betting, and wagers at races, (15)
                            manual or automated cash disbursements, (16) prepaid cards, checks, or other financial
                            merchandise or services, (17) sales of money-orders or foreign currency, (18) wire transfer
                            money orders, (19) high-risk products and services, including telemarketing sales, (20)
                            service station merchants, (21) automated fuel dispensers, (22) adult entertainment oriented
                            products or services (in any medium, including internet, telephone, or printed material),
                            (23) sales of (i) firearms, firearm parts or hardware, and ammunition; or (ii) weapons and
                            other devices designed to cause physical injury (24) internet/mail order/telephone order
                            cigarette or tobacco sales, (25) drug paraphernalia, (26) occult materials, (27) hate or
                            harmful products, (28) escort services, or (29) bankruptcy attorneys or collection agencies
                            engaged in the collection of debt.</p>
                    </div>
                    <div className="box">
                        <h2 id="section-12">Use of the Services; Limitations and Changes to the Services.</h2>
                        <p>We may change, modify, suspend, or discontinue all or any part of the Services at any time,
                            with or without reason. You acknowledge that the operation of the Services may from time to
                            time encounter technical or other problems and may not necessarily continue uninterrupted or
                            without technical or other errors and Mirron shall not be responsible to you or others for
                            any such interruptions, errors or problems or an outright discontinuance of the Services.
                            Mirron has no obligation to maintain or update the Services or to continue producing or
                            releasing new versions of the Services.</p>
                        <p>We will make reasonable efforts to keep the Services operational 24 hours a day/7 days a
                            week, except for: (i) planned downtime (of which we will endeavor to provide at least 8
                            hours prior notice); or (ii) any unavailability caused by circumstances beyond our control,
                            including but not limited to, acts of God, acts of government, flood, fire, earthquakes,
                            civil unrest, acts of terror, strikes or other labor problems or Internet service provider
                            failures or delays.</p>
                            <p>As a User of the Services, you agree to follow all applicable rules and laws and take
                                full responsibility for any promotion you offer via the Services.</p>
                            <p>When you publish content or information using the "public" setting, it means that
                                everyone, including people outside of the Mirron community, will have access to that
                                information, and we may not have control over what they do with it, including limiting
                                their ability to repost or re-publish such information</p>
                            <p>We always appreciate your feedback or other suggestions about Mirron, but you understand
                                that we may use them and you hereby grant us all rights to such suggestions without any
                                obligation to compensate you for them (just as you have no obligation to offer
                                them).</p>
                            <p className="list-title">We do our best to keep Mirron safe and spam free, but can't
                                guarantee it. In order to help us do so, you agree not to:</p>
                            <ul>
                                <li>send or otherwise post unauthorized commercial communications (such as spam) on the
                                    Services.
                                </li>
                                <li>collect Users' content or information, or otherwise access the Services, using
                                    automated means (such as harvesting bots, robots, spiders, or scrapers) without our
                                    permission.
                                </li>
                                <li>engage in unlawful multi-level marketing, such as a pyramid scheme, on the
                                    Services.
                                </li>
                                <li>upload viruses or other malicious code.</li>
                                <li>solicit login information or Account IDs or access an account belonging to someone
                                    else.
                                </li>
                                <li>bully, intimidate, or harass any User.</li>
                                <li>post content that is hateful, threatening, pornographic, or that contains nudity or
                                    graphic or gratuitous violence.
                                </li>
                                <li>develop, advertise or otherwise market alcohol-related or other mature content.</li>
                                <li>use Mirron to do anything unlawful, misleading, malicious, or discriminatory.</li>
                                <li>do anything that could disable, overburden, or impair the proper working of Mirron
                                    or the Services, such as a denial of service attack.
                                </li>
                                <li>facilitate or encourage any violations of these Terms of Service.</li>
                            </ul>
                            <p>To make sure we are able to provide a service to our Users and customers, we need to make
                                sure our pages are accurate and up-to-date. To help us do so, you agree to:</p>
                            <ul>
                                <li>keep your Registration Data and contact information accurate and up-to-date.</li>
                                <li>keep your Account IDs and Account information confidential and to not share your
                                    login information or Account IDs, let anyone else access your Account, or do
                                    anything else that might jeopardize the security of your Account.
                                </li>
                            </ul>
                    </div>
                    <div className="box">
                        <h2 id="section-13">Proprietary Rights</h2>
                        <p>The Services contains content and technology of the Company that is protected by copyright,
                            trademark, patent, trade secret and other laws. The Company owns intellectual property
                            rights to any protectable part of the Services, including but not limited to the design,
                            artwork, logos, functionality, and documentation (collectively, the "Company Property"). You
                            may not copy, modify, or reverse engineer any part of the Services or the Company
                            Property.</p>
                        <p>In order to operate the Services, the Company needs to make certain use of your publicly
                            posted Content. Therefore, by posting, uploading or submitting to Mirron, or making
                            available for inclusion in publicly accessible areas of Mirron, any text, /assets/images, photos,
                            graphics, audio or video, including any content protected by intellectual property rights
                            (collectively, "Content"), you represent that you have full authorization to do so. You also
                            hereby grant Mirron a non-exclusive, transferable, sub-licensable, royalty-free, worldwide
                            license to use, distribute, reproduce, modify, adapt, publicly perform, publicly display and
                            make derivative works of such Content in connection with the Services and any services or
                            products affiliated with the Services, regardless of the form of media used or of whether
                            such services or products now exist or are developed in the future.</p>
                        <p>You agree to not post any /assets/images containing written text or text overlays. Images may not
                            have symbols or text-/assets/images anywhere in the photo, including but not limited to shapes,
                            logos, or emoticons, etc. Mirron reserves the right to remove or hide or change any /assets/images
                            without notice.</p>
                        <p>When you delete Content, it is deleted in a manner similar to emptying the recycle bin on a
                            computer. However, you understand that removed Content may persist in backup copies for a
                            reasonable period of time (but will not be available to others).</p>
                        <p>Subject to the terms and conditions hereof, Company hereby grants each Professional a
                            limited, revocable, non-sublicensable license to display the logos, trademarks or other
                            design marks of the Company that were provided by the Company to Professional for use solely
                            in connection with your use of the Services, promotion of Styling Services offered via the
                            Services or other uses expressly permitted by the Company in writing. Notwithstanding such
                            permitted uses and license, you acknowledge that all derivative designs and artwork which
                            utilize the Company's logo or other Company Property (collectively, "Derivative Works") are
                            the sole property of the Company. No other rights are granted to you with respect to the
                            Company Property other than those rights granted explicitly herein, including with respect
                            to any Derivative Works.</p>
                        <p>Any Content posted by a User belongs to the person that posted such Content. You may use any
                            Content posted by you in any way without restriction. You may only use Content posted by
                            others in the ways described in these Terms of Service.</p>
                        <p>The Company reserves the right to remove or hide any Content from the Services, at its sole
                            discretion.</p>
                        <p>The Company respects the intellectual property of others. It may, in appropriate
                            circumstances and at its discretion, disable and/or terminate the Accounts of Users who the
                            Company, in its determination, believes have repeatedly infringed others' rights. If you
                            believe that your work has been copied in a way that constitutes copyright infringement, or
                            your intellectual property rights have been otherwise violated, please provide the following
                            information to the Company's copyright agent via email to copyright@mirron.me or by mail to
                            i3DESIGN Co., LTD.</p>
                        <ul>
                            <li>an electronic or physical signature of the person authorized to act on behalf of the
                                owner of the copyright or other intellectual property interest;
                            </li>
                            <li>a description of the copyrighted work or other intellectual property that you claim has
                                been infringed;
                            </li>
                            <li>description of where the material that you claim is infringing is located on the site,
                                including a url link;
                            </li>
                            <li>your address, telephone number, and email address;</li>
                            <li>a statement by you that you have a good faith belief that the disputed use is not
                                authorized or consented to by the copyright owner, its agent, or the law; and a
                                statement by you, made under penalty of perjury, that the above information in your
                                notice is completely accurate and that you are the copyright or intellectual property
                                owner or are authorized to act on the copyright or intellectual property owner's behalf.
                            </li>
                        </ul>
                        <p>The Company reserves the right to send Professionals and Clients a newsletter from time to
                            time if they opt-in to receiving one. This communication will be highly targeted based on
                            how each Client uses Mirron and will be designed to inform Clients of how to get more value
                            from the Services.</p>
                    </div>
                    <div className="box">
                        <h2 id="section-14">Disclaimers</h2>
                        <p>YOU EXPRESSLY UNDERSTAND AND AGREE THAT:</p>
                        <p>YOUR USE OF THE SERVICES IS AT YOUR SOLE RISK. YOU ARE FULLY AND SOLELY RESPONSIBLE FOR YOUR
                            INTERACTIONS WITH OTHER USERS AND YOU AGREE THAT IT IS SOLELY YOUR RESPONSIBILITY TO TAKE
                            REASONABLE PRECAUTIONS IN ALL ACTIONS AND INTERACTIONS WITH OTHER PROFESSIONALS AND CLIENTS
                            AND IN THE PROVISION OR RECEIPT OF STYLING SERVICES. Mirron IS NOT RESPONSIBLE FOR THE
                            CONDUCT, WHETHER ONLINE OR OFFLINE, OF ANY USER OF THE SERVICES. Mirron IS UNDER NO
                            OBLIGATION TO, AND DOES NOT ROUTINELY, SCREEN ITS USERS, INQUIRE INTO THE BACKGROUND OF ITS
                            USERS OR ATTEMPT TO VERIFY INFORMATION PROVIDED BY ANY USER. WE RESERVE THE RIGHT, BUT HAVE
                            NO OBLIGATION, TO MONITOR DISPUTES BETWEEN YOU AND OTHER USERS. PLEASE CAREFULLY SELECT THE
                            TYPE OF INFORMATION THAT YOU POST ON THE SERVICES OR THROUGH THE SERVICES OR RELEASE TO
                            OTHERS. WE DISCLAIM ALL LIABILITY, REGARDLESS OF THE FORM OF ACTION, FOR THE ACTS OR
                            OMISSIONS OF OTHER PARTICIPANTS OR USERS.</p>
                        <p>IT IS POSSIBLE FOR OTHERS TO OBTAIN PERSONAL INFORMATION ABOUT YOU DUE TO YOUR USE OF THE
                            SERVICES OR STYLING SERVICES, AND THAT THE RECIPIENT MAY USE SUCH INFORMATION TO HARASS OR
                            INJURE YOU. WE ARE NOT RESPONSIBLE FOR THE USE OF ANY PERSONAL INFORMATION THAT YOU DISCLOSE
                            ON THE SERVICES OR THROUGH THE SERVICES. YOU UNDERSTAND THAT IN USING THE SERVICES,
                            SENSITIVE INFORMATION WILL TRAVEL THROUGH THIRD-PARTY INFRASTRUCTURE THAT IS NOT UNDER
                            Mirron'S CONTROL (SUCH AS THIRD-PARTY SERVERS). Mirron MAKES NO WARRANTY WITH RESPECT TO THE
                            SECURITY OF SUCH THIRD-PARTY INFRASTRUCTURE.</p>
                        <p>THE SERVICES ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS. Mirron AND ITS
                            SUBSIDIARIES, AFFILIATES, OFFICERS, EMPLOYEES, AGENTS, PARTNERS AND LICENSORS EXPRESSLY
                            DISCLAIM ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED
                            TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
                            NON-INFRINGEMENT.</p>
                        <p>Mirron MAKES NO WARRANTY THAT (I) THE SERVICES WILL MEET YOUR REQUIREMENTS OR EXPECTATIONS,
                            (II) THAT YOUR ACCESS TO OR USE OF THE SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE OR
                            ERROR FREE, (III) THAT ANY DEFECTS IN THE SERVICES WILL BE CORRECTED, OR (IV) THAT THE
                            SERVICES OR ANY SERVER THROUGH WHICH YOU ACCESS THE SERVICES IS FREE OF VIRUSES OR OTHER
                            HARMFUL COMPONENTS.</p>
                        <p>Mirron MAKES NO REPRESENTATIONS OR WARRANTIES ABOUT THE QUALITY, SUITABILITY, RELIABILITY,
                            TIMING, DURABILITY, LEGALITY, OR ANY OTHER ASPECT OF STYLING SERVICES OFFERED OR PROVIDED BY
                            PROFESSIONALS OR REQUESTED BY CLIENTS THROUGH USE OF THE SERVICES WHETHER IN PUBLIC,
                            PRIVATE, OR OFFLINE INTERACTIONS OR ABOUT THE ACCREDITATION, REGISTRATION OR LICENSE OF ANY
                            PROFESSIONAL.</p>
                        <p>ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SERVICES IS ACCESSED AT
                            YOUR OWN DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR
                            COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF ANY SUCH MATERIAL.</p>
                        <p>NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM Mirron OR THROUGH OR
                            FROM THE SERVICES SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THESE TERMS OF
                            SERVICE.</p>
                    </div>
                    <div className="box">
                        <h2 id="section-15">Limitation of Liability and Release</h2>
                        <p>TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT SHALL Mirron OR ITS AFFILIATES, OFFICERS,
                            EMPLOYEES, AGENTS, PARTNERS AND LICENSORS BE LIABLE FOR ANY DAMAGES WHATSOEVER, WHETHER
                            DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING, BUT
                            NOT LIMITED TO, DAMAGES FOR BODILY INJURY OR EMOTIONAL DISTRESS AND DAMAGES FOR LOSS OF
                            PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES (EVEN IF THE COMPANY HAS BEEN
                            ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), RESULTING FROM: (I) THE USE OR THE INABILITY TO
                            USE THE SERVICES; (II) STYLING SERVICES FACILITATED BY THE SERVICES OR ANY INTERACTIONS
                            BETWEEN USERS, INCLUDING WITHOUT LIMITATION ANY LIABILITIES ARISING IN CONNECTION WITH THE
                            CONDUCT, ACT OR OMISSION OF ANY USER (INCLUDING WITHOUT LIMITATION STALKING, HARASSMENT THAT
                            IS SEXUAL OR OTHERWISE, ACTS OF PHYSICAL VIOLENCE, AND DESTRUCTION OF PERSONAL PROPERTY) OR
                            ANY DISPUTE WITH ANY USER; (III) STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON THE SERVICE;
                            (IV) ANY UNAUTHORIZED ACCESS TO OR ALTERATION OF THE SERVICES OR YOUR DATA OR TRANSMISSIONS;
                            OR (V) ANY OTHER MATTER RELATING TO THE SERVICES.</p>
                        <p>Mirron EXPRESSLY DISCLAIMS ANY LIABILITY THAT MAY ARISE BETWEEN USERS RELATED TO OR ARISING
                            FROM USE OF THE SERVICES. BECAUSE Mirron IS NOT INVOLVED IN THE ACTUAL INTERACTIONS BETWEEN
                            PROFESSIONALS AND CLIENTS OR IN THE PROVISION OF ANY STYLING SERVICES, YOU HEREBY RELEASE
                            AND FOREVER DISCHARGE Mirron AND ITS AFFILIATES, OFFICERS, DIRECTORS, EMPLOYEES, AGENTS AND
                            LICENSORS FROM ANY AND ALL CLAIMS, DEMANDS, DAMAGES (ACTUAL OR CONSEQUENTIAL) OF EVERY KIND
                            AND NATURE, WHETHER KNOWN OR UNKNOWN, CONTINGENT OR LIQUIDATED, ARISING FROM OR RELATED TO
                            ANY DISPUTE OR INTERACTIONS WITH ANY OTHER USER, WHETHER ONLINE OR IN PERSON, WHETHER
                            RELATED TO THE PROVISION OF STYLING SERVICES OR OTHERWISE.</p>
                    </div>
                    <div className="box">
                        <h2 id="section-16">Indemnification</h2>
                        <p>You agree to defend, indemnify and hold harmless the Company and its affiliates, officers,
                            directors, employees, agents, partners and licensors from and against any and all claims,
                            damages, obligations, losses, liabilities, costs, debt and expenses (including but not
                            limited to attorney's fees) arising from or relating to: (i) your use of and access to the
                            Services; (ii) Styling Services facilitated by the Services or any interaction between you
                            and another user; (iii) your violation of any term of these Terms of Service; (iv) your
                            violation of any rights of a third party, including without limitation any copyright,
                            intellectual property, or privacy right; or (v) any third-party claims or damages relating
                            to death, personal injury or emotional distress arising from or related to use of the
                            Services. This defense and indemnification obligations will survive the termination of these
                            Terms of Service and your use of the Services.</p>
                    </div>
                    <div className="box">
                        <h2 id="section-17">Dispute Resolution</h2>
                        <h2 id="section-18">Informal Negotiations</h2>
                        <p>To expedite resolution and the cost of any dispute, controversy or claim related to these
                            Terms of Service ("Dispute"), you and Mirron agree to first attempt to negotiate any Dispute
                            (except those Disputes expressly excluded below) informally for at least thirty (30) days
                            before initiating any arbitration or court proceeding. Such informal negotiations will
                            commence upon written notice. Your address for such notices is your billing address, with an
                            email copy to the email address you have provided to Mirron.</p>
                    </div>
                    <div className="box">
                        <h2 id="section-19">Binding Arbitration</h2>
                        <p>If you and Mirron are unable to resolve a Dispute through informal negotiations, all claims
                            arising from use of the Services (except those Disputes expressly excluded below) shall be
                            finally and exclusively resolved by binding arbitration. Any election to arbitrate by one
                            party will be final and binding on the other. YOU UNDERSTAND THAT IF EITHER PARTY ELECTS TO
                            ARBITRATE, NEITHER PARTY WILL HAVE THE RIGHT TO SUE IN COURT OR HAVE A JURY TRIAL. The
                            arbitration will be commenced and conducted under the Commercial Arbitration Rules (the "AAA
                            Rules") of the American Arbitration Association ("AAA") and, where appropriate, the AAA's
                            Supplementary Procedures for Consumer Related Disputes ("AAA Consumer Rules"), both of which
                            are available at the AAA website www.adr.org., Your arbitration fees and your share or
                            arbitrator compensation will be governed by the AAA Rules (and, where appropriate, limited
                            by the AAA Consumer Rules). If your claim for damages does not exceed USD 10,000, Mirron
                            will pay all such fees unless the arbitrator finds that either the substance of your claim
                            or the relief sought in your Demand for Arbitration was frivolous or was brought for an
                            improper purpose (as measured by the standards set forth in the Federal Rule of Civil
                            Procedure 11(b)). The arbitration may be conducted in person, through the submission of
                            documents, by phone or online. The arbitrator will make a decision in writing, but need not
                            provide a statement of reasons unless requested by a party. The arbitrator must follow
                            applicable law, and any award may be challenged if the arbitrator fails to do so. Except as
                            otherwise provided in these Terms of Service, you and Mirron may litigate in court to compel
                            arbitration, stay proceeding pending arbitration, or to confirm, modify, vacate or enter
                            judgment on the award entered by the arbitrator.</p>
                    </div>
                    <div className="box">
                        <h2 id="section-20">Exceptions to Alternative Dispute Resolution.</h2>
                        <p>Claims where mandatory arbitration is prohibited by a valid, non-preempted law, including
                            claims under the Private Attorney General Act of 2004, California Labor Code 2698 et seq.
                            ("PAGA") to the extent waiver of such claim is deemed unenforceable by a court of competent
                            jurisdiction, are not covered by this arbitration provision. Nothing in this arbitration
                            provision will prevent either party from bringing an individual action in small claims court
                            or to seek injunctive or other equitable relief on an individual basis in a federal or state
                            court in San Francisco County, California, with respect to any dispute related to the actual
                            or threatened infringement, misappropriation or violation of a party's intellectual property
                            or proprietary rights.</p>
                    </div>
                    <div className="box">
                        <h2 id="section-21">Waiver of Right to be a Plaintiff or Class Member in a Purported Class
                            Action or Representative Proceeding.</h2>
                        <p>You and Mirron agree that any arbitration will be limited to the Dispute between Mirron and
                            you individually. YOU ACKNOWLEDGE AND AGREE THAT YOU AND Mirron ARE EACH WAIVING THE RIGHT
                            TO PARTICIPATE AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS ACTION OR
                            REPRESENTATIVE PROCEEDING. Further, unless both you and Mirron otherwise agree, the
                            arbitrator may not consolidate more than one person's claims, and may not otherwise preside
                            over any form of any class or representative proceeding.</p>
                    </div>
                    <div className="box">
                        <h2 id="section-22">Location of Arbitration.</h2>
                        <p>Arbitration will take place in San Francisco County, California. You and Mirron agree that
                            for any Dispute not subject to arbitration (other than claims proceeding in any small claims
                            court), or where no election to arbitrate has been made, the California state and Federal
                            courts located in San Francisco, California have exclusive jurisdiction and you and Mirron
                            agree to submit to the personal jurisdiction of such courts.</p>
                    </div>
                    <div className="box">
                        <h2 id="section-23">Right to Opt out of Arbitration and Class Action/Jury Trial Waiver.</h2>
                        <p>You may opt out of the foregoing arbitration and class action/jury trial waiver provision of
                            these Terms of Service by notifying Mirron in writing within 30 days of the date your first
                            registered for the Services or a Subscription or 30 days from the date these Terms of
                            Service were last updated . To opt out, you must send a written notification to Mirron at
                            i3DESIGN Co., LTD.: Legal, that includes (a) your user identification, (b) your name, (c)
                            your address, (d) your telephone number, (e) your email address and (f) a clear statement
                            indicating that you do not wish to resolve claims through arbitration and demonstrating
                            compliance with the 30-day time limit to opt out of the above arbitration and class
                            action/jury trial waiver sections.</p>
                    </div>
                    <div className="box">
                        <h2 id="section-24">Governing Law</h2>
                        <p>You and Mirron agree that, other than as set forth under the subsection entitled "Waiver of
                            Right to be a Plaintiff or Class Member in a Purported Class Action or Representative
                            Proceeding" in Section 15 above, if any portion of Section 15 entitled "Dispute Resolution"
                            is found illegal or unenforceable, that portion will be severed and the remainder of these
                            Terms of Service will be given full force and effect. Notwithstanding the foregoing, if the
                            subsection entitled "Exceptions to Alternative Dispute Resolution" in Section 15 is found to
                            be illegal or unenforceable, neither you or Mirron will elect to arbitrate any Dispute
                            falling within that portion of that subsection that is found to be illegal or unenforceable
                            and such Dispute will be decided by a court of competent jurisdiction within Tokyo, Japan,
                            and you and Mirron agree to submit to the personal jurisdiction of that court.</p>
                        <p>Except as expressly provided otherwise, these Terms of Service are governed by, and will be
                            construed under, the laws of Japan, without regard to choice of law principles.</p>
                        <p>Notwithstanding the provision in the preceding paragraph with respect to applicable
                            substantive law, the enforceability of Section 15 entitled "Dispute Resolution" shall be
                            governed by the Federal Arbitration Act (9 U.S.C., Secs. 1-16).</p>
                    </div>
                    <div className="box">
                        <h2 id="section-25">Privacy</h2>
                        <p>Our collection of data and information via the Services from Users and others is subject to
                            our Privacy Policy which is available at <a href={'/privacy_policy'}>https://mirron.me/privacy_policy</a>, and is
                            incorporated herein (the "Privacy Policy"). You understand that through your use of the
                            Services that you consent to the collection and use (as set forth in the Privacy Policy) of
                            such data and information.</p>
                    </div>
                    <div className="box">
                        <h2 id="section-26">Security</h2>
                        <p>We have implemented technical and organizational measures designed to secure your personal
                            information from accidental loss and from unauthorized access, use, alteration, or
                            disclosure. However, we cannot guarantee that unauthorized third parties will never be able
                            to defeat those measures or use your personal information for improper purposes. You
                            acknowledge that you provide your personal information at your own risk.</p>
                    </div>
                </div>
                <div className="text-menu">
                    <ul>
                        <li>
                            <a href="#section-1">
                                Terms of Service for Professionals
                                <img src="/assets/images/arrow-link-more.svg" alt=""/>
                            </a>
                        </li>
                        <li>
                            <a href="#section-2">
                                The Mirron Services
                                <img src="/assets/images/arrow-link-more.svg" alt=""/>
                            </a>
                        </li>
                        <li>
                            <a href="#section-3">
                                Mirron Platform
                                <img src="/assets/images/arrow-link-more.svg" alt=""/>
                            </a>
                        </li>
                        <li>
                            <a href="#section-4">
                                Accounts
                                <img src="/assets/images/arrow-link-more.svg" alt=""/>
                            </a>
                        </li>
                        <li>
                            <a href="#section-5">
                                Account Registration
                                <img src="/assets/images/arrow-link-more.svg" alt=""/>
                            </a>
                        </li>
                        <li>
                            <a href="#section-6">
                                Account ID
                                <img src="/assets/images/arrow-link-more.svg" alt=""/>
                            </a>
                        </li>
                        <li>
                            <a href="#section-7">
                                Your Account
                                <img src="/assets/images/arrow-link-more.svg" alt=""/>
                            </a>
                        </li>
                        <li>
                            <a href="#section-8">
                                Linking Your Account
                                <img src="/assets/images/arrow-link-more.svg" alt=""/>
                            </a>
                        </li>
                        <li>
                            <a href="#section-9">
                                Termination of Subscription
                                and Account Right to Terminate
                                <img src="/assets/images/arrow-link-more.svg" alt=""/>
                            </a>
                        </li>
                        <li>
                            <a href="#section-10">
                                Termination Effects
                                <img src="/assets/images/arrow-link-more.svg" alt=""/>
                            </a>
                        </li>
                        <li>
                            <a href="#section-11">
                                Professional Accounts
                                <img src="/assets/images/arrow-link-more.svg" alt=""/>
                            </a>
                        </li>
                        <li>
                            <a href="#section-12">
                                Use of the Services; Limitations and Changes to the Services.
                                <img src="/assets/images/arrow-link-more.svg" alt=""/>
                            </a>
                        </li>
                        <li>
                            <a href="#section-13">
                                Proprietary Rights.
                                <img src="/assets/images/arrow-link-more.svg" alt=""/>
                            </a>
                        </li>
                        <li>
                            <a href="#section-14">
                                Disclaimers.
                                <img src="/assets/images/arrow-link-more.svg" alt=""/>
                            </a>
                        </li>
                        <li>
                            <a href="#section-15">
                                Limitation of Liability and Release
                                <img src="/assets/images/arrow-link-more.svg" alt=""/>
                            </a>
                        </li>
                        <li>
                            <a href="#section-16">
                                Indemnification
                                <img src="/assets/images/arrow-link-more.svg" alt=""/>
                            </a>
                        </li>
                        <li>
                            <a href="#section-17">
                                Dispute Resolution
                                <img src="/assets/images/arrow-link-more.svg" alt=""/>
                            </a>
                        </li>
                        <li>
                            <a href="#section-18">
                                Informal Negotiations
                                <img src="/assets/images/arrow-link-more.svg" alt=""/>
                            </a>
                        </li>
                        <li>
                            <a href="#section-19">
                                Binding Arbitration
                                <img src="/assets/images/arrow-link-more.svg" alt=""/>
                            </a>
                        </li>
                        <li>
                            <a href="#section-20">
                                Exceptions to Alternative Dispute
                                Resolution.
                                <img src="/assets/images/arrow-link-more.svg" alt=""/>
                            </a>
                        </li>
                        <li>
                            <a href="#section-21">
                                Waiver of Right to be a Plaintiff or Class Member in a Purported Class Action or
                                Representative Proceeding.
                                <img src="/assets/images/arrow-link-more.svg" alt=""/>
                            </a>
                        </li>
                        <li>
                            <a href="#section-22">
                                Location of Arbitration.
                                <img src="/assets/images/arrow-link-more.svg" alt=""/>
                            </a>
                        </li>
                        <li>
                            <a href="#section-23">
                                Right to Opt out of Arbitration and Class Action/Jury Trial Waiver.
                                <img src="/assets/images/arrow-link-more.svg" alt=""/>
                            </a>
                        </li>
                        <li>
                            <a href="#section-24">
                                Governing Law.
                                <img src="/assets/images/arrow-link-more.svg" alt=""/>
                            </a>
                        </li>
                        <li>
                            <a href="#section-25">
                                Privacy
                                <img src="/assets/images/arrow-link-more.svg" alt=""/>
                            </a>
                        </li>
                        <li>
                            <a href="#section-26">
                                Security
                                <img src="/assets/images/arrow-link-more.svg" alt=""/>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    )
}