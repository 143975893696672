import {createRef, h} from 'preact'
import iconClose from "../../assets/images/icon-close.svg";
import SearchCategories from "../main-search-results/SearchCategories";
import {useState} from "preact/hooks";

export default ({value, onChange}) => {
    const [opened, setOpened] = useState(false)
    const [search, setSearch] = useState(value)
    // const ref = createRef()
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            // setSearch('')
            // ref.current.blur()
            onChange(search)
        }
    }

    return (
        <div className="input-holder">
            <form onSubmit={(e) =>  { e.preventDefault(); }} autoComplete={'off'}>
            <input
                // ref={ref}
                value={search}
                onfocusin={() => setOpened(true)}
                onfocusout={()=> setTimeout(() => setOpened(false), 500)}
                onChange={(e) => {
                    if (!e.target.value) {
                        onChange('')
                    }
                    setSearch(e.target.value)
                }}
                type="text"
                className="input-search"
                autoComplete={'off'}
                placeholder="Search by Salon Name..."
                onKeyDown={handleKeyDown}
            />
            </form>
            <button className="btn-input-clear">
                <img src={iconClose} onClick={() => setSearch('')} alt="Clear search" />
            </button>
            {opened && !search && <div className="search-dropdown">
                <SearchCategories/>
            </div>}
        </div>
    )
}