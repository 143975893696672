import {h} from 'preact'
import SelectList from "../blocks/SelectList";
import Popup from "./Popup";
import {useContext} from "preact/hooks";
import {HomeContext} from "../../context/HomeContext";

export default (props) => {
    const {getTranslation} = useContext(HomeContext);
    return (
        <Popup open={props.open} onClose={props.onClose} id="choose-area" className="choose-popup">
            <SelectList {...props} name={getTranslation('AREA', 'area')} step={3}/>
        </Popup>
    )
}