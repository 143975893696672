import {h} from 'preact'
import Popup from "./Popup";

export default ({open, onClose, onConfirm, stylist}) => {
    return (
        <Popup open={open} onClose={onClose} id="booking-details-popup" className="choose-popup">
            <div className="menu-header">
                <div className="title">
                    <button onClick={onClose} className="btn-back"><img src="/assets/images/icon-back.svg" alt="Back to previous step"/></button>
                    <h3>Booking Details</h3>
                </div>
                <button className="btn-close" onClick={onClose}><img src="/assets/images/icon-close.svg" alt="Close menu"/></button>
            </div>
            <div className="booking-details">
                <div className="card-section">
                    <div className="card-box">
                        <div className="card-holder">
                            <strong className="logo-mirron">Mirron</strong>
                            <p>You are about to book a visit to the<br/>beauty stylist, see details below.</p>
                        </div>
                    </div>
                    <div className="card-box">
                        <div className="card-holder">
                            <div className="details">
                                <h4>Salon name:</h4>

                                <p className="info"><a>{stylist?.salon_name || stylist?.fullname || stylist?.username}</a></p>
                                {/*<h4>Service:</h4>*/}
                                {/*<p className="info"><a href="#">Eyebrows & Lashes</a></p>*/}
                                <h4>Extra:</h4>
                                <span className="info-row">
                                  {stylist?.is_salon_owner && <span className="btn-salon">
                                    <img src="/assets/images/icon-house.svg" alt="Salon"/>
                                    Salon
                                  </span>}
                                    {stylist?.is_home_service && <span className="btn-service">
                                    <img src="/assets/images/icon-home-service.svg" alt="Home Service Available"/>
                                    Home Service Available
                                  </span>}
                                </span>
                                {stylist?.address && <>
                                    <h4>Salon address:</h4>
                                    <p className="info"><a>{stylist?.address}</a></p>
                                </>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="booking-details-foot">
                    <p className="text">No extra fees for booking will be applied.<br/>Customer support is available.
                    </p>
                    <button className="btn booking-created-popup-opener" onClick={onConfirm}>
                        <img src="/assets/images/icon-star.svg" alt=""/>
                        <span>Request <strong>Booking</strong></span>
                    </button>
                    <button onClick={onClose} className="btn-link-cancel">Cancel</button>
                </div>
            </div>
        </Popup>
    )
}