import {h} from 'preact'

export default ({onClick, title, onlyForDesktop = true}) => {
    let src = "/assets/images/icon-star.svg";
    return (
        <div className={onlyForDesktop ? `desktop-box` : 'mobile-box'}>
            <button onClick={onClick} className="btn-small leave-request-opener">
                <img src={src} alt="" />
                <span>{title}</span>
            </button>
        </div>
    )
}