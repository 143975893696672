export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
}

export function stripHtml(html)
{
    let tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
}

export function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function timeSince(date) {

    console.log('DATE DEBUG', date)
    let seconds = Math.floor((new Date() - date) / 1000);

    let interval = seconds / 31536000;

    if (interval > 1) {
        return Math.floor(interval) + " years";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
        return Math.floor(interval) + " months";
    }
    interval = seconds / 86400;
    if (interval > 1) {
        return Math.floor(interval) + " days";
    }
    interval = seconds / 3600;
    if (interval > 1) {
        return Math.floor(interval) + " hours";
    }
    interval = seconds / 60;
    if (interval > 1) {
        return Math.floor(interval) + " minutes";
    }
    return Math.floor(seconds) + " seconds";
}

export function validateName(name = null) {
    if (!name) {
        return false;
    }
    if (name.trim().length < 3) {
        return false;
    }
    return true;
}

export function scrollToTop () {
    if (typeof window !== "undefined")
    {
        window.scrollTo({top: 1})
        window.scrollTo({top: 0})
    }
}

export function isIos() {
    return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

export const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

export function validatePassword(pw) {

    return /[A-Z]/       .test(pw) &&
        /[a-z]/       .test(pw) &&
        /[0-9]/       .test(pw) &&
        pw.length > 7 && pw.length < 17;

}
