import {h} from 'preact'
import {route} from "preact-router";
import Popup from "./Popup";

export default ({open, onClose}) => {
    return (
        <Popup open={open} id="booking-congrats-popup" className="choose-popup">
            <div className="booking-congrats">
                <div className="menu-header">
                    <button className="btn-close" onClick={onClose}><img src="/assets/images/icon-close.svg" alt="Close menu"/></button>
                </div>
                <div className="title-holder">
                    <div className="heading">
                        <h3>
                            Congrats, your<br/>booking is<br/><span>pending</span><br/>for the approval,<br/>stay tuned!
                        </h3>
                    </div>
                    <button onClick={() => route("/")} className="btn">
                        <img src="/assets/images/icon-star.svg" alt=""/>
                        <span>Book another <strong>appointment</strong></span>
                    </button>
                </div>
            </div>
            <div className="booking-congrats-foot">
                <p>By the way, we have a cool app with<br/>extended functionality. Try it out!</p>
                <a className="btn-outline" href='https://mirron.onelink.me/CNEB/web'>
                    <img src="/assets/images/icon-explorer-dark.svg" alt=""/>
                    <span className="font-medium">Explore the app</span>
                    <img className="right" src="/assets/images/icon-apple-dark.svg" alt=""/>
                </a>
            </div>
        </Popup>
    )
}