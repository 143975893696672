import {createRef, h} from 'preact'
import Popup from "./Popup";
import {useContext, useEffect, useState} from "preact/hooks";
import {HomeContext} from "../../context/HomeContext";
import {validateName} from "../../utils/functions";

export default ({open, onSubmit, onClose, defaultCountry = null}) => {


    const homeContext = useContext(HomeContext)
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [country, setCountry] = useState(null)
    const [openedSelect, setOpenedSelect] = useState(false)
    const [errorName, setErrorName] = useState(false)
    const [errorPhone, setErrorPhone] = useState(false)
    const ref = createRef()

    useEffect(() => {
        defaultCountry && setCountry(defaultCountry)
    }, [defaultCountry])
    const setPhoneNumber = (e) => {
        let p = e.target.value
        setPhone(p.replace(/\D/g, ''))
        ref.current.value = p.replace(/\D/g, '')
    }

    const selectCountry = (country) => {
        setCountry(country);
        setOpenedSelect(false);
    }

    const submitForm = () => {
        const p = `${country.phone_code}${phone}`;
        let error = false;
        if (!validateName(name)) {
            error = true;
            setErrorName(true)
        } else {
            setErrorName(false)
        }
        if (!phone || p.length > country.max_phone_lenght || p.length < country.min_phone_lenght ) {
            error = true;
            setErrorPhone(true)
        } else {
            setErrorPhone(false)
        }
        if (!error) {
            onSubmit(p, name)
        }
    }

    return (
        <Popup open={open} id="leave-request-popup" className="choose-popup">
            <div className="menu-header">
                <div className="title">
                    <button onClick={onClose} className="btn-back"><img src="/assets/images/icon-back.svg" alt="Back to previous step"/></button>
                    <h3>Leave Request</h3>
                </div>
                <button onClick={onClose} className="btn-close"><img src="/assets/images/icon-close.svg" alt="Close menu"/></button>
            </div>
            <div className="leave-request-form">
                <div className="leave-request-card">
                    <strong className="logo-mirron">Mirron</strong>
                    <p>Please fill out the form to continue to book a visit to the beauty stylist.</p>
                    <div className="form">
                        <input value={name} onInput={(e) => setName(e.target.value)} onChange={(e) => setName(e.target.value)} type="text" className={`input-text ${errorName ? 'error' : ''}`} placeholder="Enter your name"/>
                        <div className="input-phone-row">
                            <div className={`drop-selector ${openedSelect ? 'opened' : ''}`}>
                                <div className="value" onClick={() => setOpenedSelect(!openedSelect)}>
                                    <img style={{width: '33px'}} className="selected" src={country?.flag || defaultCountry?.flag} alt={country?.name || defaultCountry?.name}/>
                                    <img className="arrow" src="/assets/images/arrow-select-down.svg" alt=""/>
                                    <span>{country?.phone_code || defaultCountry?.phone_code}</span>
                                </div>
                                <div className="drop">
                                    {homeContext.availableCountries.map(el => {
                                        return (
                                            <button key={`flags-countries-${el.name}`} className="option" onClick={() => selectCountry(el)}>
                                                <img style={{height: '20px', width: '33px'}} src={el.flag} alt={el.name}/>
                                                <span>{el.name}</span>
                                            </button>
                                        )
                                    })}

                                    {/*<button className="option"  onClick={() => selectCountry('Ghana')}>*/}
                                    {/*    <img src="/assets/images/icon-flag-ghana.svg" alt="image description"/>*/}
                                    {/*    <span>Ghana</span>*/}
                                    {/*</button>*/}
                                </div>
                            </div>
                            <input ref={ref} value={phone} onChange={setPhoneNumber} type="tel" className={`input-text ${errorPhone ? 'error' : ''}`} placeholder="000 0000"/>
                        </div>
                    </div>
                </div>
                {homeContext.settings.otp_sms_registration ?
                    <button className="btn verification-popup-opener" onClick={submitForm}>
                        <img src="/assets/images/icon-star.svg" alt=""/>
                        <span>Continue</span>
                    </button> :
                    <div style={{
                        color: '#FA6D4E',
                        fontFamily: 'Poppins',
                        fontSize: '14px',
                        textAlign: "center",
                        fontWeight: 'bold'
                    }}>
                        Sorry! The service is currently<br />unavailable. Please, try later.
                    </div>
                }
            </div>
        </Popup>
    )
}