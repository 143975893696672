
export const getLocations = async () => {
    let res = await fetch(`${process.env.PREACT_APP_API_URL}api/get-locations`, {mode: "cors"});
    res = await res.json();
    return res
}
export const getLocalizationKeys = async () => {
    let res = await fetch(`${process.env.PREACT_APP_API_URL}api/localization/texts`, {mode: "cors"});
    res = await res.json();
    return res
}

export const getInitInfo = async () => {
    let res = await fetch(`${process.env.PREACT_APP_API_URL}api/version`, {mode: "cors"});
    res = await res.json();
    return res
}

export const getSalons = async (countryId, cityId, areaId, hairServiceSlug, searchParam, page, perPage, captchaToken) => {
    let url = `${process.env.PREACT_APP_API_URL}api/get-salons?page=${page}&per_page=${perPage}`
    if (countryId) {
        url += `&countryId=${countryId}`
    }
    if (hairServiceSlug) {
        url += `&hairServiceSlug=${hairServiceSlug}`
    }
    if (cityId) {
        url += `&cityId=${cityId}`
    }
    if (areaId) {
        url += `&areaId=${areaId}`
    }
    if (searchParam) {
        url = url.replace('get-salons', 'get-salons-by-keyword')
        url += `&searchParam=${searchParam}`
    }
    const headers = new Headers();
    headers.append('http-google-recaptcha-token', captchaToken);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    let res = await fetch( url, {
        headers
    });
    res = await res.json();
    return res
}

export const getPopularStylists = async (count = 6, countryName = null) => {
    let url = `${process.env.PREACT_APP_API_URL}api/website/get-popular-stylists?count=${count}` 
    if (countryName) {
        url += `&countryName=${countryName}`
    }
    const response = await fetch(url, {mode: 'cors'});
    const json = await response.json();
    if (response.status === 200 || response.status === 201) {
        return {
            responseCode: response.status,
            success: true,
            data: json.data,
        };
    }
}
export const forgotPassword = async (email) => {
    let url = `${process.env.PREACT_APP_API_URL}api/website/forgot-password`

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
        body: JSON.stringify({
            email
        }),
    });
    const json = await response.json();
    if (response.status === 200 || response.status === 201) {
        return {
            responseCode: response.status,
            success: true,
            data: json.data,
        };
    }
}

export const resetPassword = async (email, password, passwordConfirm, token) => {
    let url = `${process.env.PREACT_APP_API_URL}api/website/reset-password`

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
        body: JSON.stringify({
            email, password, password_confirmation: passwordConfirm, token
        }),
    });
    const json = await response.json();
    if ((response.status === 200 || response.status === 201) && json.result === true) {
        return {
            responseCode: json.result,
            success: true,
            data: json.data,
        };
    }  else {
        return {
            responseCode: false,
            success: false,
            data: json.data,
        };
    }
}