import {h} from 'preact'
import SalonImageItem from "../images/SalonImageItem";

export default (props) => {
    const getImagesBlock = (countOfImages) => {
        let images = [];
        for (let i = 0; i < countOfImages; i++) {
            if (props.stylist_posts_active[i]  && props.stylist_posts_active[i].thumbnail) {
                images.push(
                <SalonImageItem
                    key={`image-salon-${props.stylist_id}-${i}`}
                    image={props.stylist_posts_active[i].thumbnail}
                    fee={props.stylist_posts_active[i].fee}
                    currency={props.stylist_posts_active[i].currency}
                    duration={props.stylist_posts_active[i].post_video_duration}
                />
                )
            } else if (!props.stylist_posts_active[i]) {
                images.push(
                    <SalonImageItem key={`image-salon-${props.id}-not-set-${i}`} image={"/assets/images/img-placeholder.png"}/>
                )
            }
        }

        return images;
    }
    return (
        <div className="card" id={props.id || undefined}>
            <a className="stylist-card">
                <span key={`salon-images-row-${props.id}`} className="images-row">
                    {getImagesBlock(3)}
                </span>
                <strong className="title-row">
                    <strong className="title">{props.salon_name || props.fullname || props.username}</strong>
                    {props.verified && <img src={'/assets/images/icon-approved.svg'} alt="Approved"/>}
                </strong>
                <span className="btn-location">
                  <img src={'/assets/images/icon-location-blue.svg'} alt={props.city + ',' + props.area}/>
                    {props.city + ', ' + props.area}
                </span>
                <span className="info-row">
                    <span>
                    {props.is_salon_owner &&
                        <span className="btn-salon">
                            <img src={'/assets/images/icon-house.svg'} alt="Salon"/>
                            Salon
                          </span>
                    }
                  </span>

                  <span>
                    {props.is_home_service &&
                        <span className="btn-service">
                            <img src={'/assets/images/icon-home-service.svg'} alt="Home Service Available"/>
                            Home Service Available
                        </span>
                    }
                  </span>
                </span>
                <span style={{cursor: 'pointer'}} className="btn small" onClick={() => props.onClickExplore(props.stylist_id)}>
                  <img src={'/assets/images/icon-star.svg'} alt=""/>
                  <span>Explore</span>
                </span>
            </a>
        </div>
    )
}