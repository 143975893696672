import {h} from 'preact'
import SelectList from "../blocks/SelectList";
import Popup from "./Popup";

export default (props) => {
    return (
        <Popup open={props.open} onClose={props.onClose} id="choose-city" className="choose-popup">
            <SelectList {...props} name={'country'} step={1}/>
        </Popup>
    )
}