import {h} from 'preact';
import FindAStylistSection from "../sections/FindAStylistSection";
import WhatIsMirronSection from "../sections/WhatIsMirronSection";
import WhatIsAppSection from "../sections/WhatIsAppSection";

export default () => {
    return (
        <>
            <FindAStylistSection />
            <WhatIsMirronSection />
            <WhatIsAppSection />
        </>
    )
}