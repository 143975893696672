import {h} from 'preact'
import Popup from "./Popup";
import iconBack from '../../assets/images/icon-back.svg'
import iconClose from '../../assets/images/icon-close.svg'
import {otherServicesMapping} from "../../constants/servicesMapping";

export default ({open, onClose, onSelect}) => {
    return (
        <Popup id="choose-others" className="choose-popup" onClose={onClose} open={open}>
            <div className="menu-header">
                <div className="title">
                    <button className="btn-back" onClick={onClose}><img src={'/assets/images/icon-back.svg'} alt="Back to previous step" />
                    </button>
                    <h3>Choose Category</h3>
                </div>
                <button className="btn-close" onClick={onClose}><img src={'/assets/images/icon-close.svg'} alt="Close menu" /></button>
            </div>
            <p className="step">Step 1/3</p>
            <div className="scrollable">
                <ul className="category-list">
                    {otherServicesMapping.map(el => {
                        return (
                            <li key={'others-service-' + el.categorySlug} onClick={() => onSelect(el)}>
                                <a>
                                    <img className={el.additionalClass} src={el.picture} alt={el.name} />
                                    <span className="category-name">{el.name}</span>
                                </a>
                            </li>
                        )
                    })}

                </ul>
            </div>
        </Popup>
    )
}