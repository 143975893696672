import {h} from 'preact'
import {useCallback, useState} from "preact/hooks";

export default () => {
    const [opened, setOpened] = useState([])

    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const toogleOpened = (blockNumber) => {
        let a = opened
        if (a.includes(blockNumber)) {
            let index = a.indexOf(blockNumber);
            if (index > -1) { // only splice array when item is found
                a.splice(index, 1); // 2nd parameter means remove one item only
            }
        } else {
            a.push(blockNumber)
        }
        setOpened(a)
        forceUpdate()
    }

    return (
        <section className="text-section">
            <div className="content">
                <div className="text-content">
                    <h1>Privacy Policy</h1>
                    <p className="date">Updated as of July 29, 2020</p>
                    <div className="heading">
                        <p>i3DESIGN Co., LTD. ("i3DESIGN", "we" or "us"), we take your privacy seriously. Please read
                            the following to learn how we treat your personal information. By using or accessing the
                            Services in any manner, you acknowledge that you accept the practices and policies outlined
                            in this Privacy Policy, and you hereby consent that we will collect, use, and share your
                            information in the following ways.</p>
                        <p>Remember that your use of Mirron's Services is at all times subject to our Terms of Use,
                            which incorporates this Privacy Policy. Any terms we use in this Policy without defining
                            them have the definitions given to them in the Terms of Use. This Privacy Policy applies
                            only to this Website and the Services and not to any third-party sites.</p>
                        <p>For clarity, "you" or "Users" of the Services may be "Clients", including anyone seeking
                            hair, beauty and other health and wellness services or "Professionals", including stylists;
                            hair, beauty, health and wellness professionals; merchants; salons; and any employee of such
                            merchants or salons (whether or not such employee independently registered for the
                            Services).</p>
                        <p>If you have any questions or comments about this Privacy Policy or our use of your personally
                            identifiable information, please contact us at <a
                                href="mailto:quality@mirron.me">quality@mirron.me</a>.</p>
                    </div>
                    <div className="box">
                        <h2 id="section-1">What this Privacy Policy Covers</h2>
                        <p>This Privacy Policy covers how we treat Personal Data that we gather when you access or use
                            our Services. "Personal Data" means any information that identifies or relates to a
                            particular individual and also includes information referred to as "personally identifiable
                            information" or "personal information" under applicable data privacy laws, rules, or
                            regulations. This Privacy Policy does not cover the practices of companies we don't own or
                            control or people we don't manage.</p>
                    </div>
                    <div className="box">
                        <h2 id="section-2">Sources of Personal Data</h2>
                        <p>We collect Personal Data about you from:</p>
                        <p className="list-title">You:</p>
                        <ul>
                            <li>when you provide such information directly to us, and</li>
                            <li>when Personal Data about you is automatically collected in connection with your use of
                                our Services.
                            </li>
                        </ul>
                        <p className="list-title">[Our subsidiaries and affiliates (together, "Affiliates"), when they
                            provide us with Personal Data about you.] Third Parties that share your Personal Data with
                            us include:</p>
                        <ul>
                            <li>Service providers. For example, we may use analytics service providers to analyze how
                                you interact and engage with the Services, or third parties may help us provide you with
                                customer support.
                            </li>
                            <li>Social networks connected to the services. If you provide your social network account
                                credentials to us or otherwise sign in to the Services through a third-party site or
                                service, you understand some content and/or information in those accounts may be
                                transmitted into your Account with us.
                            </li>
                            <li>[Advertising partners. We receive information about you from some of our service
                                providers whoassist us with marketing or promotional services related to how you
                                interact with our websites, applications, products, services, advertisements or
                                communications.]
                            </li>
                        </ul>
                    </div>
                    <div className="box">
                        <h2 id="section-3">Categories of Personal Data We Collect</h2>
                        <p>The following chart details the categories of Personal Data that we collect and have
                            collected over the past twelve (12) months. Throughout this Privacy Policy, we will refer
                            back to the categories of Personal Data listed in this chart (for example, "Category A.
                            Personal identifiers").</p>

                        <div className="slide-section mobile-box">
                            <div className={`slide-block ${opened.includes(1) ? 'opened' : ''}`}>
                                <div className="slide-heading" onClick={() => toogleOpened(1)}>
                                    <h3>Personal identifiers</h3>
                                </div>
                                <div className="slide-content">
                                    <p>First name, last name, business name and information, mailing and/or physical
                                        business address, email address, telephone number, birth date, Social Security
                                        number, appointment information, location, IP address, signature, billing,
                                        credit card, and banking information.</p>
                                    <h4>What is the source of this Personal Data?</h4>
                                    <p>You, as the User</p>
                                </div>
                            </div>
                            <div  className={`slide-block ${opened.includes(2) ? 'opened' : ''}`}>
                                <div className="slide-heading" onClick={() => toogleOpened(2)}>
                                    <h3>Customer records identified</h3>
                                </div>
                                <div className="slide-content">
                                    <p>Name, signature, Social Security number, physical characteristics or description,
                                        address, telephone number, passport number, driver's license or state
                                        identification card number, education or business and professional licensing,
                                        employment, employment history, bank account number, credit card number, debit
                                        card number and any other financial information.</p>
                                    <h4>What is the source of this Personal Data?</h4>
                                    <p>You, as the User</p>
                                </div>
                            </div>
                            <div className={`slide-block ${opened.includes(3) ? 'opened' : ''}`}>
                                <div className="slide-heading" onClick={() => toogleOpened(3)}>
                                    <h3>Internet or other similar network activity information</h3>
                                </div>
                                <div className="slide-content">
                                    <p>Website search history, or information on a User’s interaction with a website,
                                        application or advertisement.</p>
                                    <h4>What is the source of this Personal Data?</h4>
                                    <p>You, as the User</p>
                                </div>
                            </div>
                            <div className={`slide-block ${opened.includes(4) ? 'opened' : ''}`} >
                                <div className="slide-heading" onClick={() => toogleOpened(4)}>
                                    <h3>Geolocation data</h3>
                                </div>
                                <div className="slide-content">
                                    <p>Physical location or movements.</p>
                                    <h4>What is the source of this Personal Data?</h4>
                                    <p>You, as the User</p>
                                </div>
                            </div>
                            <div className={`slide-block ${opened.includes(5) ? 'opened' : ''}`}>
                                <div className="slide-heading"  onClick={() => toogleOpened(5)}>
                                    <h3>Inferences drawn from other personal information</h3>
                                </div>
                                <div className="slide-content">
                                    <p>Profile reflecting a person's preferences, characteristics.</p>
                                    <h4>What is the source of this Personal Data?</h4>
                                    <p>You, as the User</p>
                                </div>
                            </div>
                            <div className={`slide-block ${opened && opened.some(el => el === 6) ? 'opened' : ''}`}>
                                <div className="slide-heading"  onClick={() => toogleOpened(6)}>
                                    <h3>Age or date of birth</h3>
                                </div>
                                <div className="slide-content">
                                    <p>Date of birth</p>
                                    <h4>What is the source of this Personal Data?</h4>
                                    <p>You, as the User</p>
                                </div>
                            </div>
                        </div>

                        <table className="desktop-box">
                            <tr>
                                <th className="col1"></th>
                                <th className="col2">Category of Personal Data</th>
                                <th className="col3">Personal Data Collected</th>
                                <th className="col4">What is the source of this Personal Data?</th>
                            </tr>
                            <tr>
                                <td className="col1">A.</td>
                                <td className="col2"><strong>Personal identifiers</strong></td>
                                <td className="col3">First name, last name, business name and information, mailing
                                    and/or physical business address, email address, telephone number, birth date,
                                    Social Security number, appointment information, location, IP address, signature,
                                    billing, credit card, and banking information.
                                </td>
                                <td className="col4">You, as the User</td>
                            </tr>
                            <tr>
                                <td className="col1">B.</td>
                                <td className="col2"><strong>Customer records identified</strong></td>
                                <td className="col3">Name, signature, Social Security number, physical characteristics
                                    or description, address, telephone number, passport number, driver's license or
                                    state identification card number, education or business and professional licensing,
                                    employment, employment history, bank account number, credit card number, debit card
                                    number and any other financial information.
                                </td>
                                <td className="col4">You, as the User</td>
                            </tr>
                            <tr>
                                <td className="col1">C.</td>
                                <td className="col2"><strong>Internet or other similar network activity
                                    information</strong></td>
                                <td className="col3">Website search history, or information on a User’s interaction with
                                    a website, application or advertisement.
                                </td>
                                <td className="col4">You, as the User</td>
                            </tr>
                            <tr>
                                <td className="col1">D.</td>
                                <td className="col2"><strong>Geolocation data</strong></td>
                                <td className="col3">Physical location or movements.</td>
                                <td className="col4">You, as the User</td>
                            </tr>
                            <tr>
                                <td className="col1">E.</td>
                                <td className="col2"><strong>Inferences drawn from other personal information</strong>
                                </td>
                                <td className="col3">Profile reflecting a person's preferences, characteristics.</td>
                                <td className="col4">You, as the User</td>
                            </tr>
                            <tr>
                                <td className="col1">F.</td>
                                <td className="col2"><strong>Age or date of birth</strong></td>
                                <td className="col3">Date of birth</td>
                                <td className="col4">You, as the User</td>
                            </tr>
                        </table>

                        <p>The following sections provide additional information about how we collect your Personal
                            Data.</p>
                    </div>
                    <div className="box">
                        <h2 id="section-4">Information Collected Automatically</h2>
                        <p>The Services use cookies and similar technologies such as pixel tags, web beacons, clear
                            GIFs, and JavaScript (collectively, "e;Cookies") to enable our servers to recognize your web
                            browser and tell us how and when you visit and use our Services, to analyze trends, learn
                            about our user base and operate and improve our Services. Cookies are small pieces of data–
                            usually text files – placed on your computer, tablet, phone, or similar device when you use
                            that device to visit our Services. We may also supplement the information we collect from
                            you with information received from third parties, including third parties that have placed
                            their own Cookies on your device(s). Please note that because of our use of Cookies, the
                            Services do not support "Do Not Track" requests sent from a browser at this time.</p>
                        <p className="list-title">We use the following types of Cookies:</p>
                        <ul>
                            <li>Essential Cookies. Essential Cookies are required for providing you with features or
                                services that you have requested. For example, certain Cookies enable you to log into
                                secure areas of our Services. Disabling these Cookies may make certain features and
                                services unavailable.
                            </li>
                            <li>Functional Cookies. Functional Cookies are used to record your choices and settings
                                regarding our Services, maintain your preferences over time and recognize you when you
                                return to our Services. These Cookies help us to personalize our content for you, greet
                                you by name, and remember your preferences (for example, your choice of language or
                                region).
                            </li>
                            <li>Performance/Analytical Cookies. Performance/Analytical Cookies allow us to understand
                                how visitors use our Services such as by collecting information about the number of
                                visitors to the Services, what pages visitors view on our Services and how long visitors
                                are viewing pages on the Services. Performance/Analytical Cookies also help us measure
                                the performance of our advertising campaigns in order to help us improve our campaigns
                                and the Services' content for those who engage with our advertising.
                            </li>
                            <li>Retargeting/Advertising Cookies. Retargeting/Advertising Cookies collect data about your
                                online activity and identify your interests so that we can provide advertising that we
                                believe is relevant to you. For more information about this, please see the section
                                below titled "Information about Interest-Based Advertisements."
                            </li>
                        </ul>
                        <p>You can decide whether or not to accept Cookies through your internet browser's settings.
                            Most browsers have an option for turning off the Cookie feature, which will prevent your
                            browser from accepting new Cookies, as well as (depending on the sophistication of your
                            browser software) allow you to decide on acceptance of each new Cookie in a variety of ways.
                            You can also delete all Cookies that are already on your computer. If you do this, however,
                            you may have to manually adjust some preferences every time you visit a site and some
                            Services and functionalities may not work.</p>
                        <p>To explore what Cookie setting are available to you, look in the "preferences" or "options"
                            section of your browser's menu. To find out more information about Cookies, including
                            information about how to manage and delete Cookies, please visit <a
                                href="http://www.allaboutcookies.org/">http://www.allaboutcookies.org/</a>.</p>
                    </div>
                    <div className="box">
                        <h2 id="section-5">Information about Interest-Based Advertisements:</h2>
                        <p>We may serve advertisements, and also allow third-party ad networks, including third-party ad
                            servers, ad agencies, ad technology vendors and research firms, to serve advertisements
                            through the Services. These advertisements may be targeted to users who fit certain general
                            profile categories or display certain preferences or behaviors ("Interest-Based Ads").
                            Information for Internet-Based Ads (including Personal Data) may be provided to us by you,
                            or derived from the usage patterns of particular users on the Services and/or services of
                            third parties. Such information may be gathered through tracking users' activities across
                            time and unaffiliated properties, including when you leave the Services. To accomplish this,
                            we or our service providers may deliver Cookies, including a file (known as a "web beacon")
                            from an ad network to you through the Services. Web beacons allow ad networks to provide
                            anonymized, aggregated auditing, research and reporting for us andfor advertisers. Web
                            beacons also enable ad networks to serve targeted advertisements to you when you visit other
                            websites. Web beacons allow ad networks to view, edit or set their own Cookies on your
                            browser, just as if you had requested a web page from their site.</p>
                    </div>
                    <div className="box">
                        <h2 id="section-6">How We Use Your Personal Data</h2>
                        <p className="list-title">We process Personal Data to operate, improve, understand and
                            personalize our Services. We use Personal Data for the following purposes:</p>
                        <ul>
                            <li>To meet or fulfill the reason you provided the information to us.</li>
                            <li>To communicate with you about the Services, including Service announcements, updates or
                                offers.
                            </li>
                            <li>To provide support and assistance for the Services through email, SMS, or other forms of
                                communication.
                            </li>
                            <li>To create and manage your Account or other user profiles.</li>
                            <li>If applicable, publish information about users on the Website or Services.</li>
                            <li>To personalize website content and communications based on your preferences, including
                                targeted offers and ads served through the Services.
                            </li>
                            <li>To process Services or other transactions.</li>
                            <li>To respond to user inquiries and fulfill user requests.</li>
                            <li>To use the information Professionals submit about Clients or affiliated Professionals to
                                send Clients and Professionals emails, SMS messages, or push notifications at such
                                Client's or Professional's direction via the Services.
                            </li>
                            <li>To contact Clients to tell them about products and services that Professionals may offer
                                and to further promote Professionals and the Services.
                            </li>
                            <li>To send Users marketing communications.</li>
                            <li>To improve and develop the Services, including testing, research, analysis and product
                                development.
                            </li>
                            <li>To protect against or deter fraudulent, illegal or harmful actions and maintain the
                                safety, security and integrity of our Services.
                            </li>
                            <li>To comply with our legal or contractual obligations, resolve disputes, and enforce our
                                Terms of Service.
                            </li>
                            <li>To respond to law enforcement requests and as required by applicable law, court order,
                                or governmental regulations.
                            </li>
                            <li>In connection with a substantial corporate transaction, such as the sale of our
                                business, a divestiture, merger, consolidation, or asset sale, or in the unlikely event
                                of bankruptcy
                            </li>
                            <li>For any other business purpose stated when collecting your Personal Data or as otherwise
                                set forth in applicable data privacy laws, such as the California Consumer Privacy Act
                                (the "CCPA").
                            </li>
                        </ul>
                        <p>We will not collect additional categories of Personal Data or use the Personal Data we
                            collected for materially different, unrelated, or incompatible purposes without providing
                            you notice.</p>
                        <p>As noted in the list above, we may communicate with you if you've provided us the means to do
                            so. For example, if you've given us your email address, we may send you promotional email
                            offers or email you about your use of the Services. Also, we may receive a confirmation when
                            you open an email from us, which helps us improve our services. If you do not want to
                            receive communications from us, please indicate your preference by emailing us at
                            info@mirron.me.</p>
                    </div>
                    <div className="box">
                        <h2 id="section-7">How We Share Your Personal Data</h2>
                        <p>Disclosures of Personal Data for a Business Purpose</p>
                        <p className="list-title">We disclose your Personal Data to service providers and other parties
                            for the following business purposes:</p>
                        <ul>
                            <li>Auditing related to a current interaction and concurrent transactions, including, but
                                not limited to, counting ad impressions to unique visitors, verifying positioning and
                                quality of ad impressions, and auditing compliance with this specification and other
                                standards.
                            </li>
                            <li>Detecting security incidents, protecting against malicious, deceptive, fraudulent, or
                                illegal activity, and prosecuting those responsible for that activity.
                            </li>
                            <li>Debugging to identify and repair errors that impair existing intended functionality.
                            </li>
                            <li>Short-term, transient use of Personal Data that is not used by another party to build a
                                consumer profile or otherwise alter your consumer experience outside the current
                                interaction.
                            </li>
                            <li>Performing services on our behalf, including maintaining or servicing accounts,
                                providing customer service, fulfilling services and transactions, verifying customer
                                information, processing payments, providing advertising or marketing services, or
                                providing similar services on behalf of the business or service provider.
                            </li>
                            <li>Undertaking internal research for technological development and demonstration</li>
                            <li>Undertaking activities to verify or maintain the quality or safety of a service or
                                device that we own, manufacture, was manufactured for us, or control.
                            </li>
                        </ul>
                        <p>We disclose your Personal Data to the following categories of service providers and other
                            parties:</p>
                        <p className="list-title">Service providers, including:</p>
                        <ul>
                            <li>Payment processors</li>
                            <li>Ad networks</li>
                            <li>Hosting and other technology and communications providers</li>
                            <li>Analytics providers</li>
                            <li>Staff augmentation and contract personnel</li>
                        </ul>
                        <p className="list-title">Parties who acquire your Personal Data through an acquisition or other
                            change of control.</p>
                        <ul>
                            <li>Personal Data may be transferred to a third party if we undergo a merger, acquisition,
                                bankruptcy or other transaction in which that third party assumes control of our
                                business (in whole or in part)
                            </li>
                        </ul>
                        <p className="list-title">Other parties at your direction.</p>
                        <ul>
                            <li>Other users (where you post information publicly or as otherwise necessary to a
                                transaction initiated or authorized by you through the Services)
                            </li>
                            <li>Social media services (if you intentionally interact with them through your use of the
                                Services)
                            </li>
                            <li>Third-party business partners who you access through the Services</li>
                            <li>Other parties authorized by you</li>
                        </ul>
                        <p className="list-title">Over the past twelve months, we have disclosed the following
                            categories of your Personal Data to service providers or other parties for the business
                            purposes listed above:</p>
                        <ul>
                            <li>Personal identifiers.</li>
                            <li>Customer records identified by state law.</li>
                            <li>Internet or other similar network activity information.</li>
                            <li>Geolocation data.</li>
                            <li>Inferences drawn from other personal information.</li>
                            <li>Age or date of birth.</li>
                        </ul>
                    </div>
                    <div className="box">
                        <h2 id="section-8">Google AdWords</h2>
                        <p>We use the Google AdWords remarketing service to advertise on third party websites (including
                            Google) to previous visitors to the Mirron website or to similar audiences online. It could
                            mean that we advertise to previous visitors who have browsed on our site. This could be in
                            the form of an advertisement on the Google search results page, or a site in the Google
                            Display Network. Google may include in-ads notice labels to disclose interest-based
                            advertising to their users. Third-party vendors, including Google, use cookies to serve ads
                            based on someone's past visits to the Website. Any data collected will be used in accordance
                            with our own privacy policy and Google's privacy policy. Google reserves the right to
                            display to users which remarketing lists users are on, along with the corresponding domain
                            name. You can set preferences for how Google advertises to you using the Google Ad
                            Preference Manager (<a
                                href="https://www.google.com/settings/ads/onweb/">https://www.google.com/settings/ads/onweb/</a>),
                            and you can opt out of interest-based advertising entirely by cookie settings or permanently
                            using a browser plugin.</p>
                    </div>
                    <div className="box">
                        <h2 id="section-9">Personal Data of Children</h2>
                        <p>As noted in the Terms of Use, by using the Website and our related Services, you are
                            representing that you are at least 18. We do not knowingly collect or solicit Personal Data
                            from children under 13; if you are a child under 13, please do not attempt to register for
                            or otherwise use the Services or send us any Personal Data. If we learn we have collected
                            Personal Data from a child under 13, we will delete that information as quickly as possible.
                            If you believe that a child under 13 may have provided us Personal Data, please contact us
                            at <a href="mailto:quality@mirron.me">quality@mirron.me</a>.</p>
                    </div>
                    <div className="box">
                        <h2 id="section-10">Sales of Personal Data</h2>
                        <p>We have not sold your Personal Data over the last 12 months.</p>
                    </div>
                    <div className="box">
                        <h2 id="section-11">Data Security and Retention</h2>
                        <p>We seek to protect your Personal Data from unauthorized access, use and disclosure using
                            appropriate physical, technical, organizational and administrative security measures based
                            on the type of Personal Data and how we are processing that data.</p>
                        <p>You should also help protect your data by appropriately selecting and protecting your
                            password and/or other sign-on mechanism; limiting access to your computer or device and
                            browser; and signing off after you have finished accessing your account. Although we work to
                            protect the privacy of your account and other Personal Data that we hold in our records,
                            please be aware that no method of transmitting data over the Internet or storing data is
                            completely secure. We cannot guarantee the complete security of any Personal Data you share
                            with us, and we are not responsible for the theft, destruction, loss, or the intentional or
                            inadvertent disclosure of your information or content.</p>
                        <p>You should never transmit personal or identifying information to other Users. If you suspect
                            someone is trying to obtain your personal data by impersonating us or misleading you or
                            other members of our community about such a person's relationship to us or to our Services,
                            please contact us at <a href="mailto:quality@mirron.me">quality@mirron.me</a>.</p>
                        <p>We retain Personal Data about you for as long as you have an open account with us or as
                            otherwise permitted in the Terms of Service. In some cases we retain Personal Data for
                            longer, if doing so is necessary to comply with our legal obligations, resolve disputes or
                            collect fees owed, or is otherwise permitted or required by applicable law, rule or
                            regulation. We retain some Client information in a depersonalized or aggregated form but not
                            in a way that would identify you personally. In the event that your Professional
                            Subscription and/or Account is terminated or suspended, Mirron reserves the right to promote
                            you to Clients using information collected during your subscription term, including but not
                            limited to, a general description of your business, customer reviews or photographs, but
                            excluding your contact information (such as phone number, address or email address).</p>
                    </div>
                    <div className="box">
                        <h2 id="section-12">Changes to this Privacy Policy</h2>
                        <p>We're constantly trying to improve our Services, so we may need to change this Privacy Policy
                            from time to time as well, but we will alert you to changes by placing a notice on the
                            Mirron website, by sending you an email, and/or by some other means. Please note that if
                            you've opted not to receive legal notice emails from us (or you haven't provided us with
                            your email address), those legal notices will still govern your use of the Services, and you
                            are still responsible for reading and understanding them. If you use the Services after any
                            changes to the Privacy Policy have been posted, that means you agree to all of the changes.
                            Use of information we collect is subject to the Privacy Policy in effect at the time such
                            information is collected.</p>
                    </div>
                </div>
                <div className="text-menu">
                    <ul>
                        <li>
                            <a href="#section-1">
                                What this Privacy Policy Covers
                                <img src="/assets/images/arrow-link-more.svg" alt=""/>
                            </a>
                        </li>
                        <li>
                            <a href="#section-2">
                                Sources of Personal Data
                                <img src="/assets/images/arrow-link-more.svg" alt=""/>
                            </a>
                        </li>
                        <li>
                            <a href="#section-3">
                                Categories of Personal Data We Collect
                                <img src="/assets/images/arrow-link-more.svg" alt=""/>
                            </a>
                        </li>
                        <li>
                            <a href="#section-4">
                                Information Collected Automatically
                                <img src="/assets/images/arrow-link-more.svg" alt=""/>
                            </a>
                        </li>
                        <li>
                            <a href="#section-5">
                                Information about Interest-Based Advertisements:
                                <img src="/assets/images/arrow-link-more.svg" alt=""/>
                            </a>
                        </li>
                        <li>
                            <a href="#section-6">
                                How We Use Your Personal Data
                                <img src="/assets/images/arrow-link-more.svg" alt=""/>
                            </a>
                        </li>
                        <li>
                            <a href="#section-7">
                                How We Share Your Personal Data
                                <img src="/assets/images/arrow-link-more.svg" alt=""/>
                            </a>
                        </li>
                        <li>
                            <a href="#section-8">
                                Google AdWords
                                <img src="/assets/images/arrow-link-more.svg" alt=""/>
                            </a>
                        </li>
                        <li>
                            <a href="#section-9">
                                Personal Data of Children
                                <img src="/assets/images/arrow-link-more.svg" alt=""/>
                            </a>
                        </li>
                        <li>
                            <a href="#section-10">
                                Sales of Personal Data
                                <img src="/assets/images/arrow-link-more.svg" alt=""/>
                            </a>
                        </li>
                        <li>
                            <a href="#section-11">
                                Data Security and Retention
                                <img src="/assets/images/arrow-link-more.svg" alt=""/>
                            </a>
                        </li>
                        <li>
                            <a href="#section-12">
                                Changes to this Privacy Policy
                                <img src="/assets/images/arrow-link-more.svg" alt=""/>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    )
}