import {h} from 'preact'
import {capitalizeFirstLetter} from "../../utils/functions";

export default ({stylist}) => {
    let name = stylist?.salon_name || stylist?.fullname || stylist?.username;
    let avatar;
    if (!stylist?.user_image || stylist?.user_image.indexOf('default') > -1) {
        avatar = <div className="avatar">
            <div className="avatar-placeholder">{capitalizeFirstLetter(name).slice(0,1)}</div>
        </div>;
    } else {
        avatar = <div className="avatar">
            <img style={{width: '56px',height: '56px', objectFit: 'cover'}} src={stylist?.user_image || stylist?.profile_photo_thumbnail || ''}
                 alt={stylist?.user_image || name}/>
        </div>
    }

    return (
        <div className="booking-card">
            <div className="title-row">
                {avatar}
                <h2 className="title">{name}</h2>
                {!!stylist?.approved &&
                    <img className="icon-approved" src="/assets/images/icon-approved.svg" alt="Approved"/>}
            </div>
            <span className="btn-location">
                            <img src="/assets/images/icon-location-blue.svg" alt="Ave Abelemkpe, Acra"/>
                {stylist?.area?.name}, {stylist?.city?.name}, {stylist?.country?.name}
                          </span>
            <div className="service-row">
                <span className="btn-service">
                {stylist?.is_home_service &&
                    <>
                        <img src="/assets/images/icon-home-service.svg" alt="Home Service Available"/>
                        Home Service Available
                    </>
                }
                </span>
                {stylist?.review_rating && <div className="rating">
                    <img src="/assets/images/icon-star-rating.svg" alt={stylist?.review_rating}/>
                    {stylist?.review_rating}
                </div>}
            </div>
        </div>
    )
}