import React from "preact-compat";
import {useState} from "preact/hooks";

export default ({ posts = []}) => {
    let postsFiltered = posts.filter(el => !!el.thumbnail)
    const [count, setCount] = useState(postsFiltered.length > 3 ? postsFiltered.length > 6 ? 9 : 6 : 3);

    const getImagesBlock = (countOfImages) => {
        let images = [];
        for (let i = 0; i < countOfImages; i++) {
            if (postsFiltered[i] && postsFiltered[i].thumbnail) {
                images.push(
                    <div className="image">
                        <img src={postsFiltered[i].thumbnail} alt="Post Image"/>
                        <span className="price">{postsFiltered[i].currency}{postsFiltered[i].fee}</span>
                        <span className="duration">{postsFiltered[i].post_video_duration }</span>
                    </div>
                )
            } else if (!postsFiltered[i]) {
                images.push(<div className="image"><img src="/assets/images/img-placeholder.png" alt="Post Image"/></div>)
            }
        }
        if (postsFiltered.length > countOfImages) {
            images[count - 1] = <div className="image">
                <img src={postsFiltered[countOfImages -1].thumbnail} alt="Post Image"/>
                <button onClick={() => setCount(count + 3)} className="btn-more">See<br/>more</button>
            </div>
        }
        return images;
    }

    return (
        <div className="images-column">
        <div className="portfolio-images">
            {postsFiltered.length && <h2>Portfolio</h2>}
            {postsFiltered.length && <div className="images-list">
                {getImagesBlock(count)}
            </div>}
        </div>
        </div>
    )
}