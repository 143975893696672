import {h} from 'preact'
import {useContext} from "preact/hooks";
import PopupContext from "../../context/HomeContext";

export default ({
                    id = '', open, className = '', children
                }) => {
    const classesMenu = `popup ${className} ${open ? 'visible' : ''}`;
    return (
        <>
            <div id={id} className={classesMenu}>
                {children}
            </div>
        </>
    )
}