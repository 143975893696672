import {h} from 'preact'
import iconBest from "../../assets/images/icon-best.svg";
import iconLocation from "../../assets/images/icon-location.svg";
import iconSearchMedium from "../../assets/images/icon-search-medium.svg";
import {useContext, useEffect, useState} from "preact/hooks";
import {HomeContext} from "../../context/HomeContext";
import {servicesMapping} from "../../constants/servicesMapping";
import {useDidUpdate} from "../../utils/hooks/useDidUpdate";
import {get} from "enzyme/build/configuration";

export default () => {
    const context = useContext(HomeContext);

    console.log('HOME_CONTEXT', context)
    const [city, setCity] = useState(null)
    const getCity = () => {
        let city;
        if (context.isGhana) {
            city = 'Accra'
        } else if(context.isKenya) {
            city = 'Nairobi'
        } else if(context.isSouthAfrica) {
            city = 'Johannesburg'
        } else {
            city = 'Lagos'
        }
        setCity(city)
    }
    
    useEffect(() => {
        getCity()
    }, [context.isGhana, context.isKenya, context.isNigeria])
    
    useEffect(() => {
        getCity()
    }, [])
    
    return (
        <ul className="search-categories">
            <li>
                <a onClick={() => {
                    context.openPopup('countries', true)
                }} id={'search-categories-top-salons'}>
                    <img src={iconBest} alt="Mirron Top Salons" />
                    <span><strong>Mirron</strong>&nbsp;Top Salons</span>
                </a>
            </li>
            <li>
                <a onClick={() => context.findInCapital(city)} id={'search-categories-find-in-capital'}>
                    <img src={iconLocation} alt={"Salons in " + city} />
                    <span>Salons in&nbsp;<strong>{city}</strong></span>
                </a>
            </li>
            <li>
                <a onClick={() => context.openPopup('hair-services')} id={'search-categories-hair-services'}>
                    <img src={iconSearchMedium} alt="Salons doing Hair services" />
                    <span>Salons doing&nbsp;<strong>Hair services</strong></span>
                </a>
            </li>
            <li>
                <a onClick={() => context.setService(servicesMapping.filter(el => el.categorySlug === 'nail salon')[0])} id={'search-categories-nails'}>
                    <img src={iconSearchMedium} alt="Salons doing Nails" />
                    Salons doing&nbsp;<strong>Nails</strong>
                </a>
            </li>
            <li>
                <a onClick={() => context.setService(servicesMapping.filter(el => el.categorySlug === 'spa')[0])} id={'search-categories-spa'}>
                    <img src={iconSearchMedium} alt="Salons doing Spa" />
                    <span>Salons doing&nbsp;<strong>Spa</strong></span>
                </a>
            </li>
            <li>
                <a onClick={() => context.setService(servicesMapping.filter(el => el.categorySlug === 'make-up')[0])} id={'search-categories-makeup'}>
                    <img src={iconSearchMedium} alt="Salons doing Make-up" />
                    <span>Salons doing&nbsp;<strong>Make-up</strong></span>
                </a>
            </li>
            <li>
                <a onClick={() => context.setService(servicesMapping.filter(el => el.categorySlug === 'eyebrows and lashes')[0])}  id={'search-categories-lashes'}>
                    <img src={iconSearchMedium} alt="Salons doing Eyebrows & Lashes"/>
                    <span>Salons doing&nbsp;<strong>Eyebrows & Lashes</strong></span>
                </a>
            </li>
        </ul>
    )
}