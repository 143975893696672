import {h} from 'preact'
import {route} from "preact-router";
import {stripHtml} from "../../utils/functions";

export default ({slug, title, content, img}) => {
    return (
        <a href={`/articles/${slug}`} className="article-preview" >
            <span className="image">
                <span className="image-cropper">
                    <img src={img} alt={title}/>
                </span>
            </span>
            <span className="info">
                <strong className="title">{title}</strong>
                <span className="text">{stripHtml(content)}</span>
            </span>
        </a>
    )
}