import { useState, useEffect } from 'preact/hooks';

export default function useWindowDimensions() {

    const hasWindow = typeof window !== 'undefined';

    function getWindowDimensions() {
        const width = hasWindow ? window.innerWidth : null;
        const height = hasWindow ? window.innerHeight : null;
        return {
            width,
            height,
        };
    }

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [windowDimensionsViewport, setWindowDimensionsViewport] = useState(getWindowDimensions());

    useEffect(() => {
        if (hasWindow) {
            // eslint-disable-next-line no-inner-declarations
            function handleResize() {
                setWindowDimensions(getWindowDimensions());
            }

            function handleResizeViewport(e) {
                setWindowDimensionsViewport({width: e.target.width, height: e.target.height})
            }

            window.addEventListener('resize', handleResize);
            window.visualViewport.addEventListener('resize', handleResizeViewport);
            return () => {
                window.removeEventListener('resize', handleResize);
                window.visualViewport.removeEventListener('resize', handleResizeViewport);
            };
        }
    }, [hasWindow]);

    return {windowDimensions, windowDimensionsViewport};
}