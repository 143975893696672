import {h} from 'preact'
import {forgotPassword} from "../../data-providers/common-data-provicer";
import {useState, useContext} from "preact/hooks";
import {HomeContext} from "../../context/HomeContext";
import BookNowButton from "../buttons/BookNowButton";
import useWindowDimensions from "../../utils/hooks/useWindowDimention";
import {validateEmail} from "../../utils/functions";

const ForgotYourPassword = () => {
    const { windowDimensionsViewport, windowDimensions } = useWindowDimensions();
    const [email, setEmail] = useState('')
    const {openPopup} = useContext(HomeContext)
    const [emailErrorMessage, setEmailErrorMessage] = useState(false)
    const submit = (e) => {

        let isValid = true;
        if (!validateEmail(email)) {
            isValid = false
            setEmailErrorMessage('Email is not valid');
        } else {
            setEmailErrorMessage(false)
        }
        if (isValid) {
            e.preventDefault();
            e.stopPropagation();
            forgotPassword(email)
            openPopup('forgot-password-request-success')
            setEmail('')
        }
    }
    return (
        <section className="contact-us">
            <div className="content">
                <div className="contact-us-form">
                            <div className="reset-password">
                                <div className="head2">
                                    <div className="heading">
                                        <h1 style={{
                                            /*
                                            height: 174px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
                                             */
                                            //url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzEiIGhlaWdodD0iMzAiIHZpZXdCb3g9IjAgMCAzMSAzMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEuOTE4NDYgMTMuNDMxMkM5LjUwMTQ3IDE2LjI2MjEgMTcuMjA3MiAxOS4yMzYxIDI0LjI0NDYgMjMuMjU2MyIgc3Ryb2tlPSIjRkE2RDRFIiBzdHJva2Utd2lkdGg9IjMuMDQ2NTkiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIvPgo8cGF0aCBkPSJNMTUuMjQyNyAxLjY2NTUzQzIwLjMyNjQgNi45MjgyMSAyNC43MzE1IDEyLjg4MTggMjkuMzA0MyAxOC41NTI1IiBzdHJva2U9IiNGQTZENEUiIHN0cm9rZS13aWR0aD0iMy4wNDY1OSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+Cjwvc3ZnPgo=)
                                            background: 'url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzEiIGhlaWdodD0iMzAiIHZpZXdCb3g9IjAgMCAzMSAzMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEuOTE4NDYgMTMuNDMxMkM5LjUwMTQ3IDE2LjI2MjEgMTcuMjA3MiAxOS4yMzYxIDI0LjI0NDYgMjMuMjU2MyIgc3Ryb2tlPSIjRkE2RDRFIiBzdHJva2Utd2lkdGg9IjMuMDQ2NTkiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIvPgo8cGF0aCBkPSJNMTUuMjQyNyAxLjY2NTUzQzIwLjMyNjQgNi45MjgyMSAyNC43MzE1IDEyLjg4MTggMjkuMzA0MyAxOC41NTI1IiBzdHJva2U9IiNGQTZENEUiIHN0cm9rZS13aWR0aD0iMy4wNDY1OSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+Cjwvc3ZnPgo=) no-repeat 18% 0%',
                                            height: '174px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}>
                                            Forgot your
                                            <span className="underlined middle"><mark style={{
                                                //url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjkiIGhlaWdodD0iMzEiIHZpZXdCb3g9IjAgMCAyOSAzMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yOC4wOTgxIDE1LjMyMUMyMy40Njc0IDE1LjMyMSAyMC4wMzczIDEzLjg1NjUgMTcuNzYzMSAxMS40NjE5QzE1LjQ4OSA5LjA2NzM3IDE0LjA5ODEgNS40NTU1NCAxNC4wOTgxIDAuNTc5NTlDMTQuMDk4MSA1LjQ1NTU0IDEyLjcwNzMgOS4wNjczNyAxMC40MzMyIDExLjQ2MTlDOC4xNTkwMSAxMy44NTY1IDQuNzI4ODQgMTUuMzIxIDAuMDk4MTQzOSAxNS4zMjFDNC43Mjg4NCAxNS4zMjEgOC4xNTkwMSAxNi43ODU2IDEwLjQzMzIgMTkuMTgwMUMxMi43MDczIDIxLjU3NDcgMTQuMDk4MSAyNS4xODY1IDE0LjA5ODEgMzAuMDYyNUMxNC4wOTgxIDI1LjE4NjUgMTUuNDg5IDIxLjU3NDcgMTcuNzYzMSAxOS4xODAxQzIwLjAzNzMgMTYuNzg1NiAyMy40Njc0IDE1LjMyMSAyOC4wOTgxIDE1LjMyMVoiIGZpbGw9IiNEOTNDREMiLz4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yOC4wOTgxIDE1LjMyMUMyMy40Njc0IDE1LjMyMSAyMC4wMzczIDEzLjg1NjUgMTcuNzYzMSAxMS40NjE5QzE1LjQ4OSA5LjA2NzM3IDE0LjA5ODEgNS40NTU1NCAxNC4wOTgxIDAuNTc5NTlDMTQuMDk4MSA1LjQ1NTU0IDEyLjcwNzMgOS4wNjczNyAxMC40MzMyIDExLjQ2MTlDOC4xNTkwMSAxMy44NTY1IDQuNzI4ODQgMTUuMzIxIDAuMDk4MTQzOSAxNS4zMjFDNC43Mjg4NCAxNS4zMjEgOC4xNTkwMSAxNi43ODU2IDEwLjQzMzIgMTkuMTgwMUMxMi43MDczIDIxLjU3NDcgMTQuMDk4MSAyNS4xODY1IDE0LjA5ODEgMzAuMDYyNUMxNC4wOTgxIDI1LjE4NjUgMTUuNDg5IDIxLjU3NDcgMTcuNzYzMSAxOS4xODAxQzIwLjAzNzMgMTYuNzg1NiAyMy40Njc0IDE1LjMyMSAyOC4wOTgxIDE1LjMyMVoiIGZpbGw9InVybCgjcGFpbnQwX2xpbmVhcl8xMDEyXzQzMjYyKSIvPgo8ZGVmcz4KPGxpbmVhckdyYWRpZW50IGlkPSJwYWludDBfbGluZWFyXzEwMTJfNDMyNjIiIHgxPSI1LjQxODE0IiB5MT0iMjYuNTI0NSIgeDI9IjIzLjU4MTQiIHkyPSI1LjAzMzA3IiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSI+CjxzdG9wIHN0b3AtY29sb3I9IiM3N0VDQzUiLz4KPHN0b3Agb2Zmc2V0PSIwLjUzIiBzdG9wLWNvbG9yPSIjN0I4MUQ3Ii8+CjxzdG9wIG9mZnNldD0iMSIgc3RvcC1jb2xvcj0iI0VCODVFRiIvPgo8L2xpbmVhckdyYWRpZW50Pgo8L2RlZnM+Cjwvc3ZnPgo=)
                                                background: 'url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjkiIGhlaWdodD0iMzEiIHZpZXdCb3g9IjAgMCAyOSAzMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yOC4wOTgxIDE1LjMyMUMyMy40Njc0IDE1LjMyMSAyMC4wMzczIDEzLjg1NjUgMTcuNzYzMSAxMS40NjE5QzE1LjQ4OSA5LjA2NzM3IDE0LjA5ODEgNS40NTU1NCAxNC4wOTgxIDAuNTc5NTlDMTQuMDk4MSA1LjQ1NTU0IDEyLjcwNzMgOS4wNjczNyAxMC40MzMyIDExLjQ2MTlDOC4xNTkwMSAxMy44NTY1IDQuNzI4ODQgMTUuMzIxIDAuMDk4MTQzOSAxNS4zMjFDNC43Mjg4NCAxNS4zMjEgOC4xNTkwMSAxNi43ODU2IDEwLjQzMzIgMTkuMTgwMUMxMi43MDczIDIxLjU3NDcgMTQuMDk4MSAyNS4xODY1IDE0LjA5ODEgMzAuMDYyNUMxNC4wOTgxIDI1LjE4NjUgMTUuNDg5IDIxLjU3NDcgMTcuNzYzMSAxOS4xODAxQzIwLjAzNzMgMTYuNzg1NiAyMy40Njc0IDE1LjMyMSAyOC4wOTgxIDE1LjMyMVoiIGZpbGw9IiNEOTNDREMiLz4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yOC4wOTgxIDE1LjMyMUMyMy40Njc0IDE1LjMyMSAyMC4wMzczIDEzLjg1NjUgMTcuNzYzMSAxMS40NjE5QzE1LjQ4OSA5LjA2NzM3IDE0LjA5ODEgNS40NTU1NCAxNC4wOTgxIDAuNTc5NTlDMTQuMDk4MSA1LjQ1NTU0IDEyLjcwNzMgOS4wNjczNyAxMC40MzMyIDExLjQ2MTlDOC4xNTkwMSAxMy44NTY1IDQuNzI4ODQgMTUuMzIxIDAuMDk4MTQzOSAxNS4zMjFDNC43Mjg4NCAxNS4zMjEgOC4xNTkwMSAxNi43ODU2IDEwLjQzMzIgMTkuMTgwMUMxMi43MDczIDIxLjU3NDcgMTQuMDk4MSAyNS4xODY1IDE0LjA5ODEgMzAuMDYyNUMxNC4wOTgxIDI1LjE4NjUgMTUuNDg5IDIxLjU3NDcgMTcuNzYzMSAxOS4xODAxQzIwLjAzNzMgMTYuNzg1NiAyMy40Njc0IDE1LjMyMSAyOC4wOTgxIDE1LjMyMVoiIGZpbGw9InVybCgjcGFpbnQwX2xpbmVhcl8xMDEyXzQzMjYyKSIvPgo8ZGVmcz4KPGxpbmVhckdyYWRpZW50IGlkPSJwYWludDBfbGluZWFyXzEwMTJfNDMyNjIiIHgxPSI1LjQxODE0IiB5MT0iMjYuNTI0NSIgeDI9IjIzLjU4MTQiIHkyPSI1LjAzMzA3IiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSI+CjxzdG9wIHN0b3AtY29sb3I9IiM3N0VDQzUiLz4KPHN0b3Agb2Zmc2V0PSIwLjUzIiBzdG9wLWNvbG9yPSIjN0I4MUQ3Ii8+CjxzdG9wIG9mZnNldD0iMSIgc3RvcC1jb2xvcj0iI0VCODVFRiIvPgo8L2xpbmVhckdyYWRpZW50Pgo8L2RlZnM+Cjwvc3ZnPgo=) 0% 53% no-repeat'
                                            }}><em>password?</em></mark></span>
                                        </h1>
                                    </div>
                                </div>
                                <div className="leave-request-form" style={{paddingTop: '0px'}}>
                                    <div className="leave-request-card">
                                        <div className="form">
                                            <div className="password-row">
                                            <input onChange={(e) => {setEmail(e.target.value)}} value={email} type="email" className={`input-text ${emailErrorMessage ? 'error' : ''}`}
                                                   placeholder={windowDimensions.width > 1024 ?'Please enter your email' : 'Email'}/>
                                                {emailErrorMessage && <p className="error-message">{emailErrorMessage}</p>}
                                            </div>
                                        </div>
                                        <div className={`bottom-btn-holder`} style={{top: `${windowDimensionsViewport.height - 20 - 56}px`}}>
                                            <button className="btn" onClick={submit}>
                                                <img src="/assets/images/icon-star.svg" alt=""/>
                                                <span>Reset password</span>
                                            </button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                </div>
            </div>
        </section>
    )
}

export default ForgotYourPassword