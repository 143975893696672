import {h} from 'preact'
import ReviewCard from "./ReviewCard";
import {useState} from "preact/hooks";

export default ({reviews}) => {
    const [expanded, setExpanded] = useState(false)
    return (
        <div className="reviews-section">
            <h3>Reviews</h3>

            {reviews.length === 0 && <div style={{paddingBottom: 0}} className="salon-info">
                <div className="box">
                    <h5 style={{
                        fontSize: '16px',
                        fontWeight: 400
                    }}>This stylist has no reviews yet. </h5></div></div>}
            {reviews.map(review => {
                return (
                    <ReviewCard review={review} key={`key-review-${review.fullname}`}/>
                )
            }).slice(0, !expanded ? 2 : undefined)}
            {!expanded && reviews.length > 2 && <div className="btn-more-holder" onClick={() => setExpanded(true)}>
                <a  style={{cursor: 'pointer'}}>
                    See more
                    <img src="/assets/images/arrow-link-more.svg" alt="See more"/>
                </a>
            </div>}
        </div>
    )
}