import {h} from 'preact'
import BookingContextProvider from "../../context/BookingContextProvider";
import SalonInfoContent from "./SalonInfoContent";
import Helmet from "preact-helmet";

export default (props) => {
    return (
        <BookingContextProvider salonId={props.matches.id}>
            <Helmet title={"Mirron"}>
                <title>Mirron</title>
            </Helmet>
            <SalonInfoContent />
        </BookingContextProvider>
    )
}