import {h} from 'preact'
import {useContext} from "preact/hooks";
import {HomeContext} from "../../context/HomeContext";


export default () => {

    const context = useContext(HomeContext)
    const instagram = (context.isNigeria || context.isKenya || context.isSouthAfrica) ? 'https://www.instagram.com/mirron.ng/' : 'https://www.instagram.com/mirron.gh/'
    const facebook = (context.isNigeria || context.isKenya || context.isSouthAfrica) ? 'https://www.facebook.com/Mirron.ng/' : 'https://www.facebook.com/mirron.gh/'
    const pinterest = (context.isNigeria || context.isKenya || context.isSouthAfrica) ? 'https://www.pinterest.com/mirronbeauty/' : 'https://www.pinterest.com/mirronbeauty/'
    const tiktok = (context.isNigeria || context.isKenya || context.isSouthAfrica) ? 'https://www.tiktok.com/@mirron.ng' : 'https://www.tiktok.com/@mirron.gh'
    const YouTube = 'https://www.youtube.com/channel/UCW_UircjfHWdRXIUKlmkUOw'
    return (
        <footer id="footer">
            <div className="footer">
                <div className="logo-box">
                    <strong className="logo-mirron"><a href="/">Mirron</a></strong>
                    <ul className="social-networks">
                        <li><a href={instagram}><img src={'/assets/images/icon-instagram.svg'} alt="Instagram" /></a></li>
                        <li><a href={facebook}><img src={'/assets/images/icon-facebook.svg'} alt="Facebook" /></a></li>
                        {/*<li><a href="#"><img src={'/assets/images/icon-whatsapp.svg'} alt="WhatsApp" /></a></li>*/}
                        <li><a href={YouTube}><img src={'/assets/images/icon-youtube.svg'} alt="YouTube" /></a></li>
                        <li><a href={pinterest}><img src={'/assets/images/icon-pinterest.svg'} alt="Pinterest" /></a></li>
                        <li><a href={tiktok}><img src={'/assets/images/icon-tiktok.svg'} alt="Tiktok" /></a></li>
                    </ul>
                </div>
                <div className="footer-nav">
                    <ul>
                        <li><a href="/">Home</a></li>
                        <li><a href="/articles">Blog</a></li>
                        {/*<li><a href="#">About Us</a></li>*/}
                    </ul>
                    <ul>
                        <li><a href="/terms">Terms & Conditions</a></li>
                        <li><a href="/privacy_policy">Privacy Policy</a></li>
                        {/*<li><a href="#">Contact Us</a></li>*/}
                    </ul>
                </div>
                <div className="text">
                    <p>Mirron is an informational service provider and not a beauty salon or salon provider.
                        Beauty services are provided by third party salons.</p>
                </div>
            </div>
            <p className="copyright">Copyright ©️ {new Date().getFullYear()} i3DESIGN Co., Ltd. <span>All Rights Reserved</span></p>
        </footer>
    )
}
