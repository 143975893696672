import {h} from 'preact'
import {useContext} from "preact/hooks";
import {HomeContext} from "../../context/HomeContext";


export default (props) => {
    const {openPopup, setService} = useContext(HomeContext)
    const getOnclick = props.categorySlug === 'hair' ? () => openPopup('hair-services') : (props.categorySlug === 'others' ? () => openPopup('others-services') : () => setService(props))
    return (
        <li>
            <a href={props.slug} onClick={getOnclick}>
                <picture>
                    <source srcset={props.pictureDsk} media="(min-width: 1025px)"/>
                    <img className={props.imageClass} src={props.picture} alt="Nails" />
                </picture>
                <span className="category-name">{props.name}</span>
            </a>
        </li>
    )
}