import {h} from 'preact'
import {servicesMapping} from "../../constants/servicesMapping";
import HairService from "../blocks/HairService";


export default () => {
    return (
        <section className="find-stylist">
            <div className="content">
                <div className="heading">
                    <h1>
                        <span className="title"><span>Find stylist</span></span>
                        in a <mark>click</mark> with<br />
                        <mark className="underlined">Mirron</mark>
                    </h1>
                </div>
                <ul className="category-list tiles">
                    {servicesMapping.map(element => {
                        return (
                            <HairService key={`${element.name}-service-homepage`} {...element} />
                        )
                    }) }
                </ul>
            </div>
        </section>
    )
}