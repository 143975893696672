export const getSalonInfo = async (salonId) => {
    let res = await fetch(`${process.env.PREACT_APP_API_URL}api/get-salon-info/${salonId}`, {mode: "cors"});
    res = await res.json();
    return res
}

export const sendVerification = async (phoneNumber, token) => {
    let response = await fetch(`${process.env.PREACT_APP_API_URL}api/send_verify_code`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'http-google-recaptcha-token': token,
        },
        body: JSON.stringify({phone_number: phoneNumber}),
    })
    const json = await response.json();
    if (response.status === 200 || response.status === 201) {
        return {
            responseCode: response.status,
            success: true,
            data: json,
        };
    }
}

export const verifyPhoneNumber = async (phone, userName, code) => {
    const body = {
        phone_number: phone,
        username: userName,
        code,
    };
    const response = await fetch(`${process.env.PREACT_APP_API_URL}api/verify_code`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
        body: JSON.stringify(body),
    });
    const json = await response.json();
    if (response.status === 200 || response.status === 201) {
        return {
            responseCode: response.status,
            success: true,
            data: json,
        };
    } else {
        return {
            responseCode: response.status,
            success: false,
        };
    }
}

export const createBooking = async (stylistId, phoneNumber, oneTimeCode, service = null) => {
    const body = {
        stylist_id: stylistId,
        phone_number: phoneNumber,
        oneTimeCode,
        service
    }
    const response = await fetch(`${process.env.PREACT_APP_API_URL}api/website/bookings`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
        body: JSON.stringify(body),
    });

    const json = await response.json();
    if (response.status === 200 || response.status === 201) {
        return {
            success: json.success,
        };
    }
}

