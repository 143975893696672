import {h} from 'preact'

export default ({days}) => {
    const items = []
    days.map(el => items[el.day] = el)
    return (
        <table className="schedule-table">
            <tr>
                <th>Mon</th>
                <th>Tue</th>
                <th>Wed</th>
                <th>Thu</th>
                <th>Fri</th>
                <th>Sat</th>
                <th>Sun</th>
            </tr>
            <tr>
                <td>{items[1]?.enabled && items[1]?.startTime || '-'}</td>
                <td>{items[2]?.enabled && items[2]?.startTime || '-'}</td>
                <td>{items[3]?.enabled && items[3]?.startTime || '-'}</td>
                <td>{items[4]?.enabled && items[4]?.startTime || '-'}</td>
                <td>{items[5]?.enabled && items[5]?.startTime || '-'}</td>
                <td>{items[6]?.enabled && items[6]?.startTime || '-'}</td>
                <td>{items[7]?.enabled && items[7]?.startTime || '-'}</td>
            </tr>
            <tr>
                <td>{items[1]?.enabled && items[1]?.endTime || '-'}</td>
                <td>{items[2]?.enabled && items[2]?.endTime || '-'}</td>
                <td>{items[3]?.enabled && items[3]?.endTime || '-'}</td>
                <td>{items[4]?.enabled && items[4]?.endTime || '-'}</td>
                <td>{items[5]?.enabled && items[5]?.endTime || '-'}</td>
                <td>{items[6]?.enabled && items[6]?.endTime || '-'}</td>
                <td>{items[7]?.enabled && items[7]?.endTime || '-'}</td>
            </tr>
        </table>
    )
}