
export const servicesMapping = [
    {
        name: 'Hair',
        categorySlug: 'hair',
        picture: '/assets/images/img1-category.png',
        pictureDsk: '/assets/images/img1-category.png',
        pictureForPopup: '/assets/images/img1-2-category.png',
        pictureForPopupDsk: '/assets/images/img1-2-category-dsk.png',
        additionalClass: 'round-1',
        subcategories: []
    },
    {
        name: 'Nails',
        categorySlug: 'nail salon',
        picture: '/assets/images/img2-category.png',
        pictureDsk: '/assets/images/img2-category-dsk.jpg',
        additionalClass: 'round-2',
        pictureForPopup: '/assets/images/img2-category.png',
        pictureForPopupDsk: '/assets/images/img2-2-category-dsk.png'
    },
    {
        name: 'Spa',
        categorySlug: 'spa',
        picture: '/assets/images/img3-category.png',
        pictureDsk: '/assets/images/img3-category-dsk.jpg',
        additionalClass: 'round-3',
        pictureForPopup: '/assets/images/img3-category.png',
        pictureForPopupDsk: '/assets/images/img3-2-category-dsk.png'
    },
    {
        name: 'Make-up',
        categorySlug: 'make-up',
        picture: '/assets/images/img4-category.png',
        pictureDsk: '/assets/images/img4-category-dsk.jpg',
        additionalClass: 'round-4',
        pictureForPopup: '/assets/images/img4-2-category.png',
        pictureForPopupDsk: '/assets/images/img4-2-category-dsk.png'
    },

    {
        name: 'Eyebrows & Lashes',
        categorySlug: 'eyebrows and lashes',
        picture: '/assets/images/img5-category.png',
        pictureDsk: '/assets/images/img5-category-dsk.jpg',
        additionalClass: 'round-4',
        pictureForPopup: '/assets/images/img5-2-category.png',
        pictureForPopupDsk: '/assets/images/img5-2-category-dsk.png'
    },
    {
        name: 'Others',
        categorySlug: 'others',
        picture: '/assets/images/img6-category.png',
        pictureDsk: '/assets/images/img6-category-dsk.jpg',
        additionalClass: 'round-3',
        pictureForPopup: '/assets/images/img6-category.png',
        pictureForPopupDsk: '/assets/images/img6-category-dsk.jpg'
    }
]

export const hairServicesMapping = [
    {
        name: 'Braids',
        categorySlug: 'braids',
        picture: '/assets/images/img1-category.png',
        pictureDsk: '/assets/images/img1-category-dsk.jpg',
    },
    {
        name: 'Dreadlocks',
        categorySlug: 'natural',
        picture: '/assets/images/img7-category.png',
        pictureDsk: '/assets/images/img7-category-dsk.jpg',
    },
    {
        name: 'Relaxer',
        categorySlug: 'relaxer',
        picture: '/assets/images/img8-category.png',
        pictureDsk: '/assets/images/img8-category-dsk.jpg',
    },
    {
        name: 'Wigs & Weave-on',
        categorySlug: 'wigs-and-weave-on',
        picture: '/assets/images/img9-category.png',
        pictureDsk: '/assets/images/img9-category-dsk.jpg',
    }
]

export const otherServicesMapping = [
    {
        name: 'Blowdry',
        categorySlug: 'blowdry',
        picture: '/assets/images/img10-category.png',
        additionalClass: 'round-1'
    },
    {
        name: 'Color',
        categorySlug: 'color',
        picture: '/assets/images/img6-category.png',
        additionalClass: 'round-2'
    },
    {
        name: 'Cut / Style',
        categorySlug: 'cutstyle',
        picture: '/assets/images/img11-category.png',
        additionalClass: 'round-3'
    },
    {
        name: 'Deep Condition',
        categorySlug: 'deep-condition',
        picture: '/assets/images/img12-category.png',
        additionalClass: 'round-4'
    },
    {
        name: 'Shampoo',
        categorySlug: 'shampoo',
        picture: '/assets/images/img13-category.png',
        additionalClass: 'round-4'
    },
    {
        name: 'Style Removal',
        categorySlug: 'style-removal-braidweave',
        picture: '/assets/images/img14-category.png',
        additionalClass: 'round-3'
    },
    {
        name: 'Trim',
        categorySlug: 'trim',
        picture: '/assets/images/img15-category.png',
        additionalClass: 'round-1'
    }
]