import {h} from 'preact'

export default ({image, fee, currency, duration = null}) => {
    return (
        <span className="image-item">
            <span className="image">
                <img src={image} />
                <span className="price">{currency}{fee}</span>
                {duration && <span className="duration">{duration}</span>}
            </span>
        </span>
    )
}