import {h} from 'preact'

export default ({serviceCategoryName, services}) => {

    return (
        <div className="services-box">
            <h5>{serviceCategoryName}:</h5>
            <div className="badges">
                {services.map((el) => {
                    return (
                        <span key={`stylist-service-${el.name}`} className="badge">{el.name}</span>
                    )
                })}

            </div>
        </div>
    )
}