import {h} from 'preact'

export default () => {
    return (
        <ul className="main-nav">
            <li><a href="/">Home</a></li>
            <li><a href="/articles">Blog</a></li>
            {/*<li><a href="#">About us</a></li>*/}
            {/*<li><a href="#">Contact us</a></li>*/}
        </ul>
    )
}