import {h} from 'preact'
import Popup from "./Popup";
import {servicesMapping} from "../../constants/servicesMapping";
import HairService from "../blocks/HairService";


export default ({open, onClose}) => {
    return (
        <Popup id={"choose-main-category"} className={'choose-popup'} open={open} onClose={onClose} >
            <div className="menu-header">
                <div className="title">
                    <button className="btn-back" onClick={onClose}><img src="/assets/images/icon-back.svg" alt="Back to previous step"/></button>
                    <h3>Choose Category</h3>
                </div>
                <button className="btn-close" onClick={onClose}><img src="/assets/images/icon-close.svg" alt="Close menu"/></button>
            </div>
            <div className="scrollable">
                <ul className="category-list">
                    {servicesMapping.map(element => {
                        return (
                            <HairService imageClass={element.additionalClass} key={`${element.name}-service-homepage`} {...element} picture={element.pictureForPopup} pictureDsk={element.pictureForPopupDsk} />
                        )
                    }) }

                </ul>
            </div>
        </Popup>
    )
}