import {h} from 'preact'
import {useState, useContext} from "preact/hooks";
import {validateEmail, validatePassword} from "../../utils/functions";
import {resetPassword} from "../../data-providers/common-data-provicer";
import {HomeContext} from "../../context/HomeContext";
import useWindowDimensions from "../../utils/hooks/useWindowDimention";
import { useEffect, useRef } from 'react';

const ResetPassword = (props) => {
    const [email, setEmail] = useState(props.email)
    const [submited, setSubmited] = useState(false)
    const [password, setPassword] = useState('')
    const [hiddenPassword, setHiddenPassword] = useState('')
    const { windowDimensionsViewport, windowDimensions } = useWindowDimensions();
    const {openPopup} = useContext(HomeContext)

    const [isHiddenPassword, setIsHiddenPassword] = useState(true);
    const [isHiddenPasswordReset, setIsHiddenPasswordReset] = useState(true);

    const token = props.matches.token

    const [passwordErrorMessage, setPasswordErrorMessage] = useState(false)
    const [hiddenPasswordErrorMessage, setHiddenPasswordErrorMessage] = useState(false)
    const [equalsPasswordsErrorMessage, setEqualsPasswordsErrorMessage] = useState(false)
    const [emailErrorMessage, setEmailErrorMessage] = useState(false)

    const containerRef = useRef(null);

    const [isButtonFixed, toggleButtonFixed] = useState(false);
    const [buttonBottom, setButtonBottom] = useState(0);

    useEffect(() => {
        const scrollListener = (e) => {
            const containerBounds = containerRef.current.getBoundingClientRect();
            const CONTAINER_PADDING = 70;

            const containerBottom = containerBounds.top + containerBounds.height + CONTAINER_PADDING;

            if(window.visualViewport.height <= containerBottom){
                toggleButtonFixed(true)
                setButtonBottom(containerBottom - window.visualViewport.height - CONTAINER_PADDING + 12)
            } else {
                toggleButtonFixed(false)
            }
        }

        window.addEventListener('scroll', scrollListener)
        window.addEventListener('resize', scrollListener)
        return () => {
            window.removeEventListener('scroll', scrollListener);
            window.removeEventListener('resize', scrollListener)
        }
    }, [containerRef])

    const submit = () => {
        if (!submited) {
            setSubmited(true)
        }
        let isValid = true;
        if (!validateEmail(email)) {
            isValid = false
            setEmailErrorMessage('Email is not valid');
        } else {
            setEmailErrorMessage(false)
        }

        if (!validatePassword(password)) {
            isValid = false
            setPasswordErrorMessage(true);
        } else {
            setPasswordErrorMessage(false)
        }
        // if (!validatePassword(hiddenPassword)) {
        //     isValid = false
        //     setHiddenPasswordErrorMessage(true);
        // } else {
        //     setHiddenPasswordErrorMessage(false)
        // }
        if (hiddenPassword !== password) {
            setEqualsPasswordsErrorMessage(true);
            isValid = false
        } else {
            setEqualsPasswordsErrorMessage(false)
        }

        if (isValid) {
            resetPassword(email, password, hiddenPassword, token).then((res) => {
                if (res.success) {
                    openPopup('password-reset-success')
                } else {
                    setEmailErrorMessage('The password reset token is invalid.')
                }

            })
        }

    }


    return (
        <section className="contact-us">
            <div className="content">
                <div className="contact-us-form">

                    <div ref={containerRef} className="reset-password">
                        <div className="heading">
                            <h1>
                                <span>Reset</span>
                                <br/>
                                <mark className="underlined"><em>Password</em></mark>
                            </h1>
                        </div>
                        <div className="leave-request-form">
                            <div className="leave-request-card">
                                <div className="form">
                                    <div className="password-row">
                                    <input disabled={true} value={email} onChange={e => setEmail(e.target.value)} type="email" className={`input-text ${emailErrorMessage ? 'error' : submited ? 'success' : ''}`}
                                           placeholder={windowDimensions.width > 1024 ?'Please enter your email' : 'Email'} />
                                    {emailErrorMessage && <p className="error-message">{emailErrorMessage}</p>}
                                    </div>
                                    <div className="password-row">
                                        <input value={password} onChange={e => setPassword(e.target.value)} type={isHiddenPassword ? "password" : 'text'}
                                               className={`input-text ${passwordErrorMessage ? 'error'  : submited ? 'success' : ''}`}
                                               placeholder="New password"/>
                                        <button onClick={() => {
                                            setIsHiddenPassword(!isHiddenPassword);
                                        }
                                        } className="btn-show"><img
                                            src={isHiddenPassword ? "/assets/images/icon-eye.svg" : "/assets/images/icon-eye-crossed.svg"}
                                            alt="image description"/></button>
                                        {passwordErrorMessage && <p className="error-message">Use 8-16 characters with a mix of numbers, uppercase and lowercase letters.</p>}
                                    </div>

                                    <div className="password-row">
                                        <input value={hiddenPassword} onChange={e => setHiddenPassword(e.target.value)} type={isHiddenPasswordReset ? "password" : 'text'}
                                               className={`input-text ${equalsPasswordsErrorMessage ? 'error'  : submited ? 'success' : ''}`}
                                               placeholder="Confirm password"/>
                                        <button onClick={() => {
                                            setIsHiddenPasswordReset(!isHiddenPasswordReset)
                                        }
                                        } className="btn-show"><img
                                            src={isHiddenPasswordReset ? "/assets/images/icon-eye.svg" : "/assets/images/icon-eye-crossed.svg"}
                                            alt="image description"/></button>
                                        {hiddenPasswordErrorMessage && <p className="error-message">Use 8-16 characters with a mix of numbers, uppercase and lowercase letters.</p>}
                                        {equalsPasswordsErrorMessage && <p className="error-message">Passwords do not match.</p>}
                                    </div>
                                </div>
                                <div className={`bottom-btn-holder`} style={{ top: "auto", position: "absolute", bottom: isButtonFixed ? buttonBottom : windowDimensionsViewport.width >= 1025 ? -140 : -70 }}>
                                    <button onClick={submit} className="btn">
                                        <img src="/assets/images/icon-star.svg" alt=""/>
                                        <span>Reset password</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default ResetPassword