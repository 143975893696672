import {h} from 'preact'
import Popup from "./Popup";
import SuccessAnimation from 'actually-accessible-react-success-animation';

export default ({open}) => {

    return (
        <Popup open={open} id="booking-created-popup" className="choose-popup">
            <div className="booking-created">
                <div className="card-box">
                    <div className="animation">
                        {open && <SuccessAnimation
                            color="#7B61FF"
                        />}
                    </div>
                    <h3>Booking created!</h3>
                    <p>Do you have any questions? Our manager<br/>will contact you soon.</p>
                </div>
            </div>
        </Popup>
    )
}