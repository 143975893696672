import {h} from 'preact'
import Popup from "./Popup";

export default (props) => {
    return (
        <Popup open={props.open} onClose={props.onClose} id="booking-congrats-popup" className="choose-popup contact-us-popup password-popup">
            <div className="booking-congrats">
                <div className="menu-header">
                    <button className="btn-close" onClick={props.onClose}><img src="/assets/images/icon-close.svg" alt="Close menu"/></button>
                </div>
                <div className="title-holder title-2">
                    <div className="heading head2">
                        <h3 className="no-bg">
                            We have <mark>e-mailed</mark> your password<br/><span>reset link</span>
                        </h3>
                    </div>
                    <button className="btn" onClick={props.onClose} >
                        <img src="/assets/images/icon-star.svg" alt=""/>
                        <span>Ok</span>
                    </button>
                </div>
            </div>
            <div className="booking-congrats-foot">
                <p>By the way, we have a cool app with<br/>extended functionality. Try it out!</p>
                <a href={"https://mirron.onelink.me/CNEB/web"}><button className="btn-outline">
                    <img src="/assets/images/icon-explorer-dark.svg" alt=""/>
                    <span className="font-medium">Explore the app</span>
                    <img className="right" src="/assets/images/icon-apple-dark.svg" alt=""/>
                </button></a>
            </div>
        </Popup>
    )
}