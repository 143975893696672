import {h} from 'preact'
import EditInfoHeader from "./EditInfoHeader";
import {useContext} from "preact/hooks";
import {HomeContext} from "../../context/HomeContext";
import SalonCard from "../blocks/SalonCard";
import {route} from "preact-router";
import SearchResultsSkeleton from "../skeletons/SearchResultsSkeleton";
import EditInfoSection from "./EditInfoSection";
import Helmet from "preact-helmet";
import SearchResultsRecommendedStylists from "./SearchResultsRecommendedStylists";
import {scrollToTop} from "../../utils/functions";

export default () => {
    const context = useContext(HomeContext)

    const openSalon = (id) => {
        route(`/stylists/${id}`)
    }


    if (!context.service && !context.area && !context.city && !context.country && !context.searchText) {
        if (!context.searchText) {

        } else
        route('/')
    }

    return (
        <>
            <section className="search-result">
                <Helmet
                    title={"Mirron"}
                />
                <div className="content">
                    <EditInfoHeader
                        area={context.area}
                        city={context.city}
                        onEditLocation={() => {
                            if (context.locations.length === 1)
                                context.openPopup('cities')
                            else context.openPopup('countries')
                        }}
                        onEditService={() => context.openPopup('find-stylist')}
                        service={context.service?.name}
                    />
                    {context.searchResultsLoaded && <div className="result-list">
                        {context.salons.map((el, index) => {
                            return (
                                <SalonCard id={`search-result-card-${index}`} key={`salon-card-${el.username}`} {...el} onClickExplore={openSalon}/>
                            )
                        })}
                    </div>}
                    {!context.searchResultsLoaded && <SearchResultsSkeleton/>}
                    {(context.page < context.maxPage)  && <div className="btn-more-holder">
                        <a  style={{cursor: 'pointer'}} onClick={context.increasePage}>
                            See more
                            <img src={'/assets/images/arrow-link-more.svg'} alt="See more"/>
                        </a>
                    </div>}

                </div>
            </section>
            {(context.page >= context.maxPage) && <SearchResultsRecommendedStylists/>}
            <EditInfoSection
                area={context.area}
                city={context.city}
                onEditLocation={() => {
                    if (context.locations.length === 1)
                        context.openPopup('cities')
                    else context.openPopup('countries')
                }}
                onEditService={() => context.openPopup('find-stylist')}
                service={context.service?.name}
            />
        </>
    )
}