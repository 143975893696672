import {createContext, h} from 'preact'
import {useEffect, useState} from "preact/hooks";

import {getArticles, getKeywords, getRecommendedArticles} from "../data-providers/blog-data-provider";
import {BLOG_ARTICLES_PER_PAGE_OPTION, BLOG_RECOMMENDED_PER_PAGE_OPTION} from "../constants/settings";

export const BlogContext = createContext({open: false, setOpen: null});

const defaultContex = {
    articlesPage: 1,
    recommendedPage: 1,
    categorySlug: null,
    articlesPageMax: false,
    recommendedArticlesPageMax: false
}

export default (props) => {

    let keywordSlug = props.keyword
    const [context, setContext] = useState(defaultContex)
    const [articles, setArticles] = useState([])
    const [popularArticles, setPopularArticles] = useState([])
    const [keywords, setKeywords] = useState([])

    const getArticlesList = (page) => {
        getArticles(page, keywordSlug).then((res) => {
            if (res.data && res.data.length < BLOG_ARTICLES_PER_PAGE_OPTION) {
                setContext({...context, articlesPageMax: true})
            }
            setArticles(articles.concat(res.data))
        })
    }

    const refresh = () => {
        setArticles([])
        setPopularArticles([])
        setContext({...context, recommendedPage: 1, articlesPage: 1})
    }

    const getRecommendedList = (page) => {
        getRecommendedArticles(page).then((res) => {
            if (res.data && res.data.length < BLOG_RECOMMENDED_PER_PAGE_OPTION) {
                setContext({...context, recommendedArticlesPageMax: true})
            }
            setPopularArticles(popularArticles.concat(res.data))

        })
    }

    const setArticlesPage = (page) => {
        setContext({...context, articlesPage: page})
        getArticlesList(page)
    }

    const setRecommendedPage = (page) => {
        setContext({...context, recommendedPage: page})
        getRecommendedList(page)
    }

    useEffect(() => {
        getArticlesList(context.articlesPage)
        getRecommendedList(context.recommendedPage)
        getKeywords().then((res) => setKeywords(res.data)).catch(e => console.log(e))
    }, [keywordSlug])

    return (
        <BlogContext.Provider value={{
            ...context,
            articles,
            popularArticles,
            keywords,
            keywordSlug,
            setContext,
            refresh,
            setArticlesPage,
            setRecommendedPage
        }}>
            {props.children}
        </BlogContext.Provider>
    )
}