import {BLOG_ARTICLES_PER_PAGE_OPTION, BLOG_RECOMMENDED_PER_PAGE_OPTION} from "../constants/settings";

export const getArticles = async (page, categorySlug = null) => {
    const response = await fetch(`${process.env.PREACT_APP_API_URL}api/website-articles?categorySlug=${categorySlug}&page=${page}&perPage=${BLOG_ARTICLES_PER_PAGE_OPTION}`);
    const json = await response.json();

    if (response.status === 200 || response.status === 201) {
        return {
            responseCode: response.status,
            success: true,
            data: json,
        };
    }
}

export const getRecommendedArticles = async (page) => {

    const response = await fetch(`${process.env.PREACT_APP_API_URL}api/website-articles/recommended?page=${page}&perPage=${BLOG_RECOMMENDED_PER_PAGE_OPTION}`);
    const json = await response.json();
    if (response.status === 200 || response.status === 201) {
        return {
            responseCode: response.status,
            success: true,
            data: json,
        };
    }
}

export const getKeywords = async () => {
    const response = await fetch(`${process.env.PREACT_APP_API_URL}api/website-articles/categories`);
    const json = await response.json();
    if (response.status === 200 || response.status === 201) {
        return {
            responseCode: response.status,
            success: true,
            data: json,
        };
    }
}

export const getArticle = async (slug) => {
    const response = await fetch(`${process.env.PREACT_APP_API_URL}api/website-articles/${slug}`);
    const json = await response.json();
    if (response.status === 200 || response.status === 201) {
        return {
            responseCode: response.status,
            success: true,
            data: json,
        };
    }
}