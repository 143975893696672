import {h} from 'preact'

export default ({onEditService, onEditLocation, service, area, city}) => {
    return (
        <div className="heading">
            <h1>
                <a style={{cursor: 'pointer'}} onClick={onEditService}>{service || 'All'}
                    <a className="icon-edit">
                        <img src={'/assets/images/icon-title-edit.svg'} alt="Edit"/>
                    </a>
                </a>
                styles in
                <span  style={{cursor: 'pointer'}} className="text" onClick={onEditLocation}>

                    <a>{city?.name || 'Everywhere'}{area?.name &&`, ${area?.name}`}</a>
                    <a className="icon-edit" href="#">
                        <img src={'/assets/images/icon-title-edit.svg'} alt="Edit"/>
                    </a>
                </span>
            </h1>
        </div>
    )
}