import {h} from 'preact'
import PostSection from "./PostSection";
import PopularArticlesBox from "./PopularArticlesBox";
import TopStylistsSection from "./TopStylistsSection";
import BlogContextProvider from "../../context/BlogContextProvider";
import {useContext} from "preact/hooks";
import {scrollToTop} from "../../utils/functions";
import {HomeContext} from "../../context/HomeContext";

export default (props) => {
    const slug = props.matches.slug
    const context = useContext(HomeContext);
    return (
        <BlogContextProvider>

            <div className="blog-section section">
            <div className="content">
                <div className="blog-column">
                    <PostSection slug={slug}/>
                    <TopStylistsSection additionalClass={'mobile'} count={3}/>

                    <div className="try-to-search mobile" onClick={scrollToTop}>
                        <h2>Didn't find your stylist? Try another<br/>
                            <span  style={{cursor: 'pointer'}} className="text"><a onClick={() => {
                                context.openPopup('search');
                                scrollToTop();
                            }}><span>Style</span></a></span>
                        </h2>
                    </div>

                    <PopularArticlesBox/>
                </div>
                <div className="stylists-column desktop">
                    <TopStylistsSection count={4}/>

                    <div className="try-to-search">
                        <h2>Didn’t match?<br/>
                            <span className="text" style={{cursor: 'pointer'}}><a onClick={scrollToTop}><span>See More</span> <img src="/assets/images/icon-eye.svg" alt=""/></a></span>
                        </h2>
                    </div>
                </div>
            </div>
            </div>
        </BlogContextProvider>
    )
}