import {h} from 'preact'
import {onlyUnique} from "../../utils/functions";
import ServiceCategoryBlock from "./ServiceCategoryBlock";

export default ({services = []}) => {
    const serviceCategories = services.map((e) => e.category_name).filter(onlyUnique)

    return (
        <div className="salon-info">
            <h4>Services</h4>

            {serviceCategories.map(service => {
                return (
                    <ServiceCategoryBlock serviceCategoryName={service} services={services.filter(el => el.category_name === service)} />
                )
            })}


        </div>
    )
}