import {h} from 'preact'
import BlogContextProvider, {BlogContext} from "../../context/BlogContextProvider";
import {useContext} from "preact/hooks";
import ArticlePreview from "../blocks/ArticlePreview";
import PopularArticlePreview from "../blocks/PopularArticlePreview";
import TopStylistsSection from "./TopStylistsSection";
import BlogSection from "./BlogSection";
import ArticlesBox from "./ArticlesBox";
import PopularArticlesBox from "./PopularArticlesBox";
import useWindowDimensions from "../../utils/hooks/useWindowDimention";
import Helmet from "preact-helmet";
import {scrollToTop} from "../../utils/functions";
import {HomeContext} from "../../context/HomeContext";

export default (props) => {

    return (
        <BlogContextProvider keyword={props.matches.slug}>
            <BlogSectionContent/>
        </BlogContextProvider>
    )
}

const BlogSectionContent = () => {
    const context = useContext(HomeContext);

    const { width } = useWindowDimensions().windowDimensions;
    return (
        <div className="blog-section section">

            <div className="content">
                <div className="blog-column">
                    <ArticlesBox />
                    <div className="try-to-search mobile">
                        <h2>Want to find your style? Try to <br/>
                            <span style={{cursor: 'pointer'}} className="text"><a onClick={() => {
                                context.openPopup('search');
                                scrollToTop();
                            }}><span> Search </span> <img
                                src="/assets/images/icon-eye.svg"
                                alt=""/></a></span>
                        </h2>
                    </div>
                    <PopularArticlesBox />
                </div>
                <div className="stylists-column">

                    <TopStylistsSection count={width > 1024 ? 4 : 3}/>
                    <div className="try-to-search mobile">
                        <h2>Didn’t match?<br/>
                            <span className="text"><a onClick={() => {
                                context.openPopup('search');
                                scrollToTop();
                            }}><span>See More</span> <img src="/assets/images/icon-eye.svg"
                                                                                          alt=""/></a></span>
                        </h2>
                    </div>
                    <div className="try-to-search desktop">
                        <h2>Want to find your style? Try to<br/>
                            <span className="text" style={{cursor: "pointer"}}><a onClick={scrollToTop}><span>Search</span> <img src="/assets/images/icon-eye.svg"
                                                                                        alt=""/></a></span>
                        </h2>
                    </div>
                </div>
            </div>
        </div>
    )
}