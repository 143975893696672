import {h} from 'preact'
import {route} from "preact-router";
import {stripHtml} from "../../utils/functions";

export default ({slug, title, content, img}) => {
    return (
        <a href={`/articles/${slug}`} className="popular-article"  >
            <span className="image">
                <span className="image-cropper">
                    <picture>
                      <source srcSet={img} media="(min-width: 1025px)" />
                      <img src={img} alt={img} />
                    </picture>
                </span>
            </span>
            <span className="info">
                <strong className="title">{title}</strong>
                <span className="text">{stripHtml(content)}</span>
            </span>
        </a>
    )
}