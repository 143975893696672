import {h} from 'preact'

import Popup from "./Popup";
import {hairServicesMapping} from "../../constants/servicesMapping";

export default ({open, onClose, onSelect}) => {

    return (
        <Popup className={'choose-popup'} open={open} id="choose-hair" onClose={onClose}>
            <div className="menu-header">
                <div className="title">
                    <button className="btn-back" onClick={onClose}><img src={'/assets/images/icon-back.svg'} alt="Back to previous step"/>
                    </button>
                    <h3>Choose Category</h3>
                </div>
                <button className="btn-close" onClick={() => {
                    onClose()
                }}><img src={'/assets/images/icon-close.svg'} alt="Close menu" /></button>
            </div>
            <p className="step">Step 1/3</p>
            <ul className="category-list tiles">
                {hairServicesMapping.map(el => {
                    return (
                        <li key={`hair-service-${el.categorySlug}`} onClick={() => onSelect(el)}>
                            <a>
                                <picture>
                                    <source srcset={el.pictureDsk} media="(min-width: 1025px)" />
                                        <img src={el.picture} alt={el.name} />
                                </picture>
                                <span className="category-name">{el.name}</span>
                            </a>
                        </li>
                    )
                })}


            </ul>
        </Popup>
    )
}