import {h} from 'preact'

import Popup from "./Popup";


export default ({open, onClose, onFind}) => {
    return (
        <Popup open={open} id="menu" onClose={onClose} className={'menu-popup'}>
            <div className="menu-header">
                <strong className="logo-mirron"><a>Mirron</a></strong>
                <button className="btn-close" onClick={() => {
                    onClose()
                }}><img src={'/assets/images/icon-close.svg'} alt="Close menu" /></button>
            </div>
            <ul className="menu-nav">
                <li><a onClick={onClose} href="/"><img src={'/assets/images/icon-home.svg'} alt="Home" />Home</a></li>
                <li><a onClick={onClose} href="/articles"><img src={'/assets/images/icon-blog.svg'} alt="Blog" />Blog</a></li>
                {/*<li><a onClick={onClose} href="#"><img src={iconChat} alt="About us" />About us</a></li>*/}
            </ul>
            <div className="menu-footer">
                <a className="btn" onClick={onFind}>
                    <img src={'/assets/images/icon-star.svg'} alt="" />
                    <span>Find a&nbsp;<strong>stylist</strong></span>
                </a>
                {/*<a className="btn-outline" href="#">*/}
                {/*    <img src={iconStar} alt="" />*/}
                {/*    <span>Get a&nbsp;<strong>free</strong>&nbsp;consultation</span>*/}
                {/*</a>*/}
                <p>Copyright ©️ {new Date().getFullYear()} i3DESIGN Co., Ltd.</p>
                <p>All Rights Reserved</p>
            </div>
        </Popup>
    )
}