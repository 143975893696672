import {h} from 'preact'
import {useEffect, useState} from "preact/hooks";
import {getArticle} from "../../data-providers/blog-data-provider";
import Helmet from "preact-helmet";

export default (props) => {
    const {slug} = props

    const [article, setArticle] = useState({title: null, img: null, slug:null, content: null})

    useEffect(() => {
        getArticle(slug).then(res => setArticle(res.data))
    }, [slug]);
    return (
        <section className="post-section">
            <Helmet
                title={article.title}
                meta={[
                    {name: "description", content: article.meta_description},
                    {name: "keywords", content: article.meta_keywords}
                ]}
            />
            <div className="post" >
                <div className="post-heading-image">

                            <img className="image" src={article.img}
                                 alt={article.title} />
                    <h1 className="post-title">{article.title}</h1>

                </div>
                <div dangerouslySetInnerHTML={{__html: article.content}} />
            </div>
        </section>
    )
}