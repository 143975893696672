import {h} from 'preact'

export default ({onEditService, onEditLocation, service, area, city}) => {
    return (
        <section className="try-another">
            <div className="content">
                <div className="heading">
                    
                    <h2>
                        Didn't find your style? Try another
                        <span className="text">
                            <a style={{cursor: 'pointer'}} onClick={onEditService}>{service || 'All'}</a>
                            <a  style={{cursor: 'pointer'}} className="icon-edit" onClick={onEditService}>
                                <img src="/assets/images/icon-title-edit.svg" alt="Edit"/>
                            </a>
                            in
                            <span  style={{cursor: 'pointer'}} className="wrap" onClick={onEditLocation}>
                                <a>{city?.name || 'Everywhere'}, {area?.name}</a>
                                <a  onClick={onEditLocation} className="icon-edit">
                                    <img src="/assets/images/icon-title-edit.svg" alt="Edit"/>
                                </a>
                            </span>
                        </span>
                    </h2>
                </div>
            </div>
        </section>
    )
}