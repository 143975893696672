import {h} from 'preact'
import Popup from "./Popup";
import {useContext, useEffect, useRef, useState} from "preact/hooks";
import {useDidUpdate} from "../../utils/hooks/useDidUpdate";
import {BookingContext} from "../../context/BookingContextProvider";

export default ({onVerify, onResend, open, onClose, onBack, verified}) => {

    const context = useContext(BookingContext)

    const [val1, setVal1] = useState('')

    const ref1 = useRef(null);

    useEffect(() => {
        if (!open) {
            setVal1('')
            ref1.current.value = '';
        }
    }, [open])
    
    const updateValues = (e) => {

        if (!e) {
            setVal1(e);
            ref1.current.value = e;
        } else {
            const val = e.replace(/\D/g, '').slice(0, 4)
            setVal1(val);
            ref1.current.value = val;

        }

    }
    const verify = () => {
        onVerify(`${ref1.current.value}`);
    }

    const resend = () => {
        context.setTimeRemain(60)
        context.setResetInterval(true)
        onResend()
    }

    const paste = (e) => {
        let val = (e.clipboardData || window.clipboardData).getData('text') || e.target.value
        val = val.replace(/\D/g, '')
        updateValues(val || '')
    }

    useDidUpdate(() => {
        if (open) {
            document.getElementById('verification-1-input').focus()
        }
    }, [open])
    useEffect(() => {
        let listener = (e) => {
            switch (e.animationName) {
                case 'onAutoFillStart':
                case 'onAutoFillCancel':
                    return (e)
            }
        }
        ref1.current.addEventListener('animationstart', listener)

    }, [])

    return (
        <Popup onClose={onClose} open={open} id="verification-popup" className="choose-popup">
            <div className="menu-header">
                <div className="title">
                    <button onClick={() => {
                        onBack();
                    }} className="btn-back"><img src="/assets/images/icon-back.svg" alt="Back to previous step"/>
                    </button>
                    <h3>Phone Verification</h3>
                </div>
                <button onClick={onClose} className="btn-close"><img src="/assets/images/icon-close.svg"
                                                                     alt="Close menu"/></button>
            </div>
            <div className="verification-form">
                <div className="verification-card">
                    <div className="card-holder">
                        <strong className="logo-mirron">Mirron</strong>
                        <p>Please enter the verification code.<br/>{context.resetInterval &&
                            <span>Resend code in {context.timeRemain} sec</span>}</p>
                        <div className="numbers-row error">
                            <div className="col">

                                <input style={{
                                    paddingLeft: val1 ? '15px' : 0,
                                }} className={'phone-number-verify-input'} onPaste={paste} type={'tel'}
                                       id={'verification-1-input'} ref={ref1}
                                       onInput={(e) => updateValues(e.target.value)} 
                                       onChange={(e) => updateValues( e.target.value)} 
                                       value={val1}
                                />
                            </div>
                            {!verified && <p className="error-message">Invalid verification code, please try again.</p>}
                        </div>
                        {!context.resetInterval &&
                            <p className="note link"><a onClick={resend} className="new-code-popup-opener">Resend code
                                via SMS</a></p>}

                    </div>
                </div>
                <button onClick={verify} className="btn booking-details-popup-opener">
                    <img src="/assets/images/icon-star.svg" alt=""/>
                    <span>Continue</span>
                </button>
            </div>
        </Popup>
    )
}