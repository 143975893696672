import {h} from 'preact'
import GoogleMapReact from 'google-map-react';
import ScheduleTable from "./ScheduleTable";
export default ({latitude, longitude, address = '', schedule = [], onClick, introduction = null}) => {
    const markerStyle = {
        position: "absolute",
        top: "100%",
        left: "50%",
        transform: "translate(-50%, -100%)",
        width: '20px'
    };
    const AnyReactComponent = () => <img style={markerStyle} src={"/assets/images/pin.png"} alt="pin" />
    // const AnyReactComponent = () => <div>MMM</div>;
                        ;

    return (
        <div className="salon-info">
            <h4>Salon info</h4>
            <div className="box">
                {introduction && <>
                    <h5>BIO:</h5>
                    <p>
                        {introduction}
                    </p>
                </>}
                {latitude && longitude && <><h5>Salon location:</h5>
                <div className="map" style={{height: '200px'}}>
                    <GoogleMapReact
                        bootstrapURLKeys={{key: 'AIzaSyBvCCj771PDAt6PXJy0uzFb_kDjW6VPExE'}}
                        yesIWantToUseGoogleMapApiInternals
                        defaultCenter={{
                            lat: Number(latitude),
                            lng: Number(longitude)
                        }} zoom={11}
                    >
                        <AnyReactComponent lat={Number(latitude)} lng={Number(longitude)} />

                    </GoogleMapReact>
                </div></>}
                <div className="btn-location">
                    <img src="/assets/images/icon-location-blue.svg"
                         alt={address}/>
                    {address}
                </div>
                <h5>Opening hours:</h5>
                <ScheduleTable days={schedule}/>

            </div>
        </div>
    )
}