import {h} from 'preact'
import {useContext} from "preact/hooks";
import {HomeContext} from "../../context/HomeContext";

export default () => {
    let context = useContext(HomeContext)
    let cashbackValue = '₦9M'
    if (context.isGhana) cashbackValue = 'GH₵ 140K'
    if (context.isKenya) cashbackValue = 'Ksh 1.8M'
    if (context.isSouthAfrica) cashbackValue = 'R100K'

    return (
        <section className="what-is-mirron">
            <div className="content">
                <div className="info">
                    <div className="heading">
                        <h2>
                            <span>What</span><br />
                            is <mark className="underlined">Mirron</mark>?
                        </h2>
                    </div>
                    <div className="text">
                        <p>We are a free online matching platform for beauty stylists and clients in Africa. You can
                            find a stylist best-fit your beauty needs closest to you on demand.</p>
                    </div>
                </div>
                <ul className="numbers-list">
                    <li>
                        <div className="number-box dark">
                            <div className="text">
                                <strong>25K+</strong>
                                <span>Number<br /> of stylists</span>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="number-box">
                            <div className="text">
                                <strong>43</strong>
                                <span>Number<br/> of services</span>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="number-box">
                            <div className="text">
                                <strong>{cashbackValue}</strong>
                                <span>Paid<br />cashback</span>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="number-box blue">
                            <div className="text">
                                <strong>12K+</strong>
                                <span>Bookings<br />via Mirron</span>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </section>
    )
}