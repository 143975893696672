import {h} from 'preact'

export default () => {
    return (
        <div className="content">
                <div className="info-column">
                    <div className="booking-card skeleton-holder">
                        <div className="title-row skeleton-holder">
                            <div className="avatar">
                                <div className="skeleton-circle"></div>
                            </div>
                            <h2 className="title">
                                <span className="skeleton-row"></span>
                                <span className="skeleton-row w-40"></span>
                            </h2>
                        </div>
                        <span className="btn-location">
                <span className="skeleton-row h-16"></span>
              </span>
                        <div className="service-row">
                            <span className="skeleton-row h-16"></span>
                        </div>
                    </div>
                    <div className="desktop-box">
                        <button className="btn-small leave-request-opener">
                            <img src="/assets/images/icon-star.svg" alt=""/>
                            <span>Book <strong>Now</strong></span>
                        </button>
                    </div>
                    <div className="portfolio-images skeleton-holder">
                        <h3>Portfolio</h3>
                        <div className="images-list">
                            <div className="image">
                                <div className="skeleton-square"></div>
                            </div>
                            <div className="image">
                                <div className="skeleton-square"></div>
                            </div>
                            <div className="image">
                                <div className="skeleton-square"></div>
                            </div>
                            <div className="image">
                                <div className="skeleton-square"></div>
                            </div>
                            <div className="image">
                                <div className="skeleton-square"></div>
                            </div>
                            <div className="image">
                                <div className="skeleton-square"></div>
                            </div>
                        </div>
                        <button className="btn-small">
                            <img src="/assets/images/icon-star.svg" alt=""/>
                            <span>Book <strong>Now</strong></span>
                        </button>
                    </div>
                    <div className="reviews-section skeleton-holder">
                        <div className="skeleton-row h-32"></div>
                        <div className="skeleton-row h-32"></div>
                        <div className="skeleton-row h-32"></div>
                        <div className="skeleton-row h-32"></div>
                    </div>
                </div>
                <div className="images-column">
                    <div className="portfolio-images">
                        <h2>Portfolio</h2>
                        <div className="images-list">
                            <div className="image">
                                <div className="skeleton-square"></div>
                            </div>
                            <div className="image">
                                <div className="skeleton-square"></div>
                            </div>
                            <div className="image">
                                <div className="skeleton-square"></div>
                            </div>
                            <div className="image">
                                <div className="skeleton-square"></div>
                            </div>
                            <div className="image">
                                <div className="skeleton-square"></div>
                            </div>
                            <div className="image">
                                <div className="skeleton-square"></div>
                            </div>
                            <div className="image">
                                <div className="skeleton-square"></div>
                            </div>
                            <div className="image">
                                <div className="skeleton-square"></div>
                            </div>
                            <div className="image">
                                <div className="skeleton-square"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}