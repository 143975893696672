import {h} from 'preact'
import iconClose from "../../assets/images/icon-close.svg"
import Popup from "./Popup";
import {useState} from "preact/hooks";
import SearchCategories from "../main-search-results/SearchCategories";
import {isIos} from "../../utils/functions";


export default ({open, onClose, value, onChange}) => {
    const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);
    const [search, setSearch] = useState(value);
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            onChange(search)
            setSearch('')
        }
    }
    return (
        <Popup open={open} onClose={onClose} className={'search-popup'}>
            <div className="menu-header">
                <strong className="logo-mirron"><a>Mirron</a></strong>
                <button className="btn-close"><img onClick={onClose} src={iconClose} alt="Close menu" /></button>
            </div>
            <div className="input-holder input-holder-search">
                <input
                    value={search}
                    onKeyDown={handleKeyDown}
                    onChange={(e) => {
                        if (!e.target.value) {
                            onChange('')
                        }
                        setSearch(e.target.value)
                    }}
                    type="text"
                    className="input-search"
                    placeholder="Search by Salon Name"
                    onFocus={() => setIsKeyboardOpen(true)}
                    onBlur={() => setIsKeyboardOpen(false)}
                />
            </div>
            {!search && <SearchCategories/>}
            {(isKeyboardOpen || search.length > 0) && <button className="btn-medium btn-continue" disabled={search.length < 3}  onClick={() => {
                setSearch('')
                onChange(search)
            }}>
                <img style={isIos() ? {top: '330px'} : {}} className={search.length >= 3 ? '' : "icon-disabled"} src={search.length >= 3 ? "/assets/images/icon-star.svg" : "/assets/images/icon-star-grey.svg"} alt=""/>
                <span>Continue</span>
            </button>}
            <div className="protected-text">This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.
            </div>
        </Popup>
    )
}