import {h} from 'preact';

import SearchInput from "../inputs/SearchInput";
import Navbar from "../navbar";
import {useContext} from "preact/hooks";
import {HomeContext} from "../../context/HomeContext";

const Header = () => {

    const context = useContext(HomeContext)
    return (
        <header id="header">
            <button className="btn-menu" onClick={() => {
                context.openPopup('menu')
            }}><img src={'/assets/images/icon-menu.svg'} alt="Menu" /></button>
            <strong className="logo-mirron"><a href="/">Mirron</a></strong>
            <button className="btn-search" onClick={() => {
                context.openPopup('search')
            }}><img src={'/assets/images/icon-search.svg'} alt="Search" /></button>
            <Navbar />
            <SearchInput value={context.searchText} onChange={context.setSearch} />


        </header>
    )
};

export default Header;
