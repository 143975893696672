import {h} from 'preact'
import BookingCard from "../blocks/BookingCard";
import BookNowButton from "../buttons/BookNowButton";
import PortfolioImagesMobile from "../blocks/PortfolioImagesMobile";
import StylistReviews from "../blocks/StylistReviews";
import SalonDetails from "../blocks/SalonDetails";
import {useContext} from "preact/hooks";
import {BookingContext} from "../../context/BookingContextProvider";
import EnterPhonePopup from "../popup/EnterPhonePopup";
import Services from "../blocks/Services";
import VerificationPopup from "../popup/VerificationPopup";
import BookingDetailsPopup from "../popup/BookingDetailsPopup";
import BookingCreatedPopup from "../popup/BookingCreatedPopup";
import BookingCongratsPopup from "../popup/BookingCongratsPopup";
import StylistProfileSkeleton from "../skeletons/StylistProfileSkeleton";
import PortfolioImagesDesktop from "../blocks/PortfolioImagesDesktop";
import {HomeContext} from "../../context/HomeContext";

export default () => {
    const context = useContext(BookingContext)
    const homeContext = useContext(HomeContext)
    const getDefaultCountry = () => {
        if (homeContext.isNigeria) {
            return homeContext.availableCountries.filter(el => el.name === 'Nigeria')[0] || null
        }
        if (homeContext.isGhana) {
            return homeContext.availableCountries.filter(el => el.name === 'Ghana')[0] || null
        }
        if (homeContext.isKenya) {
            return homeContext.availableCountries.filter(el => el.name === 'Kenya')[0] || null
        }
        if (homeContext.isSouthAfrica) {
            return homeContext.availableCountries.filter(el => el.name === 'South Africa')[0] || null
        }
        return homeContext.availableCountries[0] || null;
    }
    return (
        <section className={`stylist-profile ${context.isLoaded ? '' : 'skeleton-holder'}`}>
            {context.isLoaded && <div className="content">
                <div className="info-column">
                    <BookingCard stylist={context.salonInfo}/>
                    <BookNowButton title={<>Book <strong>Now</strong></>} onClick={() => {
                        context.setBookingStep(1)
                    }}/>
                    <PortfolioImagesMobile onClick={() => {
                        context.setBookingStep(1)
                    }} posts={context.salonInfo?.stylist_posts_active}/>
                    <StylistReviews onClickShowMore={() => {
                    }} reviews={context.salonInfo?.reviews || []}/>
                    <Services services={context.salonInfo?.hair_services}/>
                    {context.salonInfo?.is_salon_owner && <SalonDetails address={context.salonInfo?.address} latitude={context.salonInfo?.latitude}
                                   longitude={context.salonInfo?.longitude} schedule={context.salonInfo?.work_time}
                                   onClick={() => {
                                       context.setBookingStep(1)
                                   }}
                                   introduction={context.salonInfo.introduction}
                    />}
                    <BookNowButton onlyForDesktop={false}  title={<>Book <strong>Now</strong></>} onClick={() => {
                        context.setBookingStep(1)
                    }} />

                </div>

                <PortfolioImagesDesktop posts={context.salonInfo?.stylist_posts_active}/>
                <>
                    <EnterPhonePopup
                        onSubmit={(phone, name) => context.enterUser(phone, name)}
                        open={context.bookingPopupStep === 1}
                        defaultCountry={getDefaultCountry()}
                        onClose={() => context.setBookingStep(0)}
                    />
                    <VerificationPopup
                        onResend={() => context.resend()}
                        open={context.bookingPopupStep === 2}
                        onClose={() => context.setBookingStep(0)}
                        onVerify={context.verify}
                        onBack={() => context.setBookingStep(1)}
                        verified={context.verifiedCode}
                    />
                    <BookingDetailsPopup
                        open={context.bookingPopupStep === 3}
                        onClose={() => context.setBookingStep(0)}
                        onConfirm={context.confirmBooking}
                        onBack={() => context.setBookingStep(2)}
                        stylist={context.salonInfo}
                    />
                    <BookingCreatedPopup open={context.bookingPopupStep === 4}/>
                    <BookingCongratsPopup open={context.bookingPopupStep === 5}
                                          onClose={() => context.setBookingStep(0)}/>
                </>
            </div>}
            {!context.isLoaded && <StylistProfileSkeleton/>}
        </section>
    )
}