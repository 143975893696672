import {h} from 'preact'

export default () => {
    return (
        <section className="explore-app">
            <div className="content">
                <div className="info">
                    <div className="heading">
                        <h2>We are the <mark>first</mark><br />
                            <mark> beauty app </mark>
                            <span>&nbsp;in&nbsp;</span>
                            <span className="underlined"> Africa </span>
                        </h2>
                    </div>
                    <a className="btn-medium" href="https://mirron.onelink.me/CNEB/web">
                        <img src={'/assets/images/icon-explorer.svg'} alt="" />
                        <span className="font-medium">Explore the app</span>
                        <img className="right" src={'/assets/images/icon-apple.svg'} alt="" />
                    </a>
                </div>
                <div className="image">
                    <picture>
                        <source srcSet={'/assets/images/img-explore-app-dsk.png'} media="(min-width: 1025px)">
                            <img src={'/assets/images/img-explore-app.png'} alt="We are the first beauty app in Africa" />
                        </source>
                    </picture>
                </div>
            </div>
        </section>
    )
}