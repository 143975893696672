import {h} from 'preact'
import {useContext} from "preact/hooks";
import {BlogContext} from "../../context/BlogContextProvider";
import PopularArticlePreview from "../blocks/PopularArticlePreview";

export default () => {
    const context = useContext(BlogContext)
    return (
        <section className="popular-articles">
            <div className="heading">
                <h2>
                    <mark className="underlined">Popular </mark>
                    &nbsp;articles
                </h2>
            </div>
            <div className="popular-article-list">
                {context.popularArticles.map((el, index) => {
                    return (
                        <PopularArticlePreview key={`popular-article-post-${index}`} {...el} />
                    )
                })}
            </div>
            {!context.recommendedArticlesPageMax && <div className="btn-more-holder">
                <a  id={'popular-articles-btn_more'} onClick={() => context.setRecommendedPage(context.recommendedPage + 1)}>
                    More
                    <img src="/assets/images/arrow-link-more.svg" alt="See more"/>
                </a>
            </div>}
        </section>
    )
}